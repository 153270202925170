import { NativeDateAdapter } from "@angular/material/core";
import { Injectable } from '@angular/core';



@Injectable()
export class MyDateAdapter extends NativeDateAdapter {

    useUtcForDisplay = true;

    getFirstDayOfWeek(): number {
        return 1;
    }

    public createDate(year: number, month: number, date: number): Date {
        const localDate = super.createDate(year, month, date);
        const offset = localDate.getTimezoneOffset() * 60000;
        return new Date(localDate.getTime() - offset); // utcDate
    }



    public parse(number: number) {
        let date = new Date(number);
        return this.createDate(date.getFullYear(), date.getMonth(), date.getDate())
    }
}