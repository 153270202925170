import { Component, Input } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TaskService } from "src/app/provider/task.service";
import { GenericService } from "src/app/provider/generic.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalController } from "@ionic/angular";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { UserService } from "src/app/provider/user.service";
import { SelectItem } from "primeng/api";
import { People } from "src/app/models/people";
import { UserGraph } from "src/app/models/userGraph";

@Component({
  selector: "app-modal-send-mail",
  templateUrl: "./modal-send-mail.component.html",
})
export class ModalSendMailComponent {
  @Input() id: number;
  people: People[] = [];
  peopleSelected: People = new People();
  loading = false;
  mailForm = new UntypedFormGroup({
    anexos: new UntypedFormControl(false),
    subject: new UntypedFormControl("", [Validators.required]),
    comments: new UntypedFormControl(""),
  });
  emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  validateMail = true;
  fragment: DocumentFragment;
  contacts: string[] = [];

  contactCtrl = new UntypedFormControl("");
  filteredContacts: People[];
  personSelect: SelectItem;
  persons: SelectItem[] = [];
  attachHeader: string;
  profile!: UserGraph;
  comts: string;

  constructor(private taskService: TaskService, private translateService: TranslateService, private genericService: GenericService, public modalController: ModalController, private userService: UserService) {}

  async onKey(searchTextValue: string) {
    this.filteredContacts = [];
    if (searchTextValue?.length > 2) {
      this.filteredContacts = await this.userService.getContacts(searchTextValue);
    }
  }

  addCustom(email) {
    if (this.validateEmail(email.trim())) {
      this.contacts.push(email.trim());
      this.persons.push({ label: email.trim(), value: email.trim() });
      this.filteredContacts = [];
      this.contactCtrl.setValue(null);
    } else {
      this.genericService.showMessage(this.translateService.instant("validation.notMailAccount"));
    }
  }
  removeItem(i) {
    this.contacts.splice(i, 1);
    this.persons.splice(i, 1);
  }
  remove(contact: any): void {
    const index = this.contacts.indexOf(contact);

    if (index >= 0) {
      this.contacts.splice(index, 1);
      this.persons.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.peopleSelected = event.option.value;

    this.personSelect = {
      label: this.peopleSelected.displayName,
      value: this.peopleSelected.scoredEmailAddresses[0].address,
    };
    this.contacts.push(this.peopleSelected.displayName);
    this.persons.push(this.personSelect);
    this.personSelect = null;
    this.contactCtrl.setValue(null);
    this.filteredContacts = [];
  }

  async sendMail(): Promise<void> {
    this.loading = true;
    const tos = [];
    this.persons.forEach((element) => {
      tos.push(element.value);
    });
    const userGraph: UserGraph = await this.userService.getUserGraph();
    this.comts = this.translateService.instant("mail.sender").replace("@user@", userGraph.displayName) + this.mailForm.controls.comments.value;
    this.attachHeader = this.translateService.instant("mail.atHeader");
    this.fragment = document.createDocumentFragment();
    const detailsTaskElement = document.getElementById("detailsTask");
    const documentCloned = detailsTaskElement.cloneNode(true);
    this.fragment.appendChild(documentCloned);
    const element = this.fragment.getElementById("mensajeTareaFallida");
    if (element) {
      element.remove();
    }
    (await this.taskService.sendMailFromButtom(this.fragment.getElementById("detailsTask").innerHTML, tos, this.mailForm.controls.anexos.value, this.mailForm.controls.subject.value, this.comts, this.attachHeader)).subscribe({
      next: () => {
        this.loading = false;
        this.close();
        this.genericService.showMessage(this.translateService.instant("validation.sendMailCorrect"));
      },
      error: () => {
        this.loading = false;
        this.genericService.showMessage(this.translateService.instant("validation.notSendMail"));
      },
    });
  }

  close() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  validateEmail(mail: string): boolean {
    const patt = new RegExp(this.emailPattern);
    const res = patt.test(mail);
    return res;
  }
}
