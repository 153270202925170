import { ProfileOperationUpdate } from "./../models/editRoles/profileOperationUpdate";
import { FilterUpdateProfile } from "../models/filter/filterUpdateProfile";
import { RolFilter } from "../models/filter/rolFilter";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { Module } from "../models/module";
import { Operation } from "../models/operation";
import { Profile } from "../models/profile";
import { ProfileEdit } from "../models/editRoles/profileEdit";
import { ProfileUpdateResult } from "../models/editRoles/profileUpdateResult";
import { GenericService } from "./generic.service";
import { GenericResponse } from "../models/response/generic.response";

@Injectable({
  providedIn: "root",
})
export class RolesService {
  constructor(private http: HttpClient, private genericService: GenericService) {}

  public async findModules(): Promise<Module[]> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/moduleservices/getListModuleOption";

    return lastValueFrom(
      this.http
        .get<Module[]>(URL, {
          headers: headers,
        })
        .pipe(
          map((data: Module[]) => {
            return data;
          })
        )
    );
  }

  public async findOperations(): Promise<Operation[]> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/operationcontroller/getallOperation";

    return lastValueFrom(
      this.http
        .get<Operation[]>(URL, {
          headers: headers,
        })
        .pipe(
          map((data: Operation[]) => {
            return data;
          })
        )
    );
  }

  public async findProfiles(): Promise<Profile[]> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/profilecontroller/getListProfileOrderByDescription";

    return lastValueFrom(
      this.http
        .get<Profile[]>(URL, {
          headers: headers,
        })
        .pipe(
          map((data: Profile[]) => {
            return data;
          })
        )
    );
  }

  public async findProfileAllowed(filter: FilterUpdateProfile): Promise<Operation[]> {
    const headers = await this.genericService.getHeaders();
    const params = this.genericService.getParams(filter);
    const URL = this.genericService.url_gateway + "/api/operationcontroller/getListProfileAllowed";

    return lastValueFrom(
      this.http
        .get<Operation[]>(URL, {
          headers: headers,
          params: params,
        })
        .pipe(
          map((data: Operation[]) => {
            return data;
          })
        )
    );
  }

  public async findProfileNotAllowed(filter: FilterUpdateProfile): Promise<Operation[]> {
    const headers = await this.genericService.getHeaders();
    const params = this.genericService.getParams(filter);

    const method = filter.idProfile ? "getListProfileNotAllowed" : "getListProfileAllowedByModule";
    const URL = this.genericService.url_gateway + "/api/operationcontroller/" + method;

    return lastValueFrom(
      this.http
        .get<Operation[]>(URL, {
          headers: headers,
          params: params,
        })
        .pipe(
          map((data: Operation[]) => {
            return data;
          })
        )
    );
  }

  public async findProfileOperations(filter: RolFilter): Promise<GenericResponse> {
    const headers = await this.genericService.getHeaders();
    const params = this.genericService.getParams(filter);

    const URL = this.genericService.url_gateway + "/api/profileOperationcontroller/getListProfileOperation";

    return lastValueFrom(
      this.http
        .get<GenericResponse>(URL, {
          headers: headers,
          params: params,
        })
        .pipe(
          map((data: GenericResponse) => {
            return data;
          })
        )
    );
  }

  public async findProfileByFilter(filter: RolFilter): Promise<Profile[]> {
    const headers = await this.genericService.getHeaders();
    const params = this.genericService.getParams(filter);

    const URL = this.genericService.url_gateway + "/api/profilecontroller/getProfileByFilter";

    return lastValueFrom(
      this.http
        .get<Profile[]>(URL, {
          headers: headers,
          params: params,
        })
        .pipe(
          map((data: Profile[]) => {
            return data;
          })
        )
    );
  }

  public async getDataProfileOperation(idProfile: number): Promise<ProfileEdit> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/profilecontroller/getprofilepermission?idProfile=" + idProfile;

    return lastValueFrom(
      this.http
        .get<ProfileEdit>(URL, {
          headers: headers,
        })
        .pipe(
          map((data: ProfileEdit) => {
            return data;
          })
        )
    );
  }

  public async findById(nId: number): Promise<GenericResponse> {
    const headers = await this.genericService.getHeaders();
    const params = this.genericService.getParams({ id: nId });

    const URL = this.genericService.url_gateway + "/api/profileOperationcontroller/getById";

    return lastValueFrom(
      this.http
        .get<GenericResponse>(URL, {
          headers: headers,
          params: params,
        })
        .pipe(
          map((data: GenericResponse) => {
            return data;
          })
        )
    );
  }

  public async deleteListProfileByLong(ids: number[]): Promise<boolean> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/profileOperationcontroller/deleteListProfile";

    return lastValueFrom(
      this.http
        .post(URL, ids, {
          headers: headers,
        })
        .pipe(
          map((data: any) => {
            return data;
          })
        )
    );
  }

  public async updateProfile(profileUpdate: ProfileOperationUpdate): Promise<ProfileUpdateResult> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/profilecontroller/updateProfile";

    return lastValueFrom(
      this.http
        .post<ProfileUpdateResult>(URL, JSON.stringify(profileUpdate), {
          headers: headers,
        })
        .pipe(
          map((data: ProfileUpdateResult) => {
            return data;
          })
        )
    );
  }

  public async findProfileUserAssigned(nId: number): Promise<Profile[]> {
    const headers = await this.genericService.getHeaders();
    const params = this.genericService.getParams({ userId: nId });
    const URL = this.genericService.url_gateway + "/api/profilecontroller/getListProfileUserAssigned";

    return lastValueFrom(
      this.http
        .get<Profile[]>(URL, {
          headers: headers,
          params: params,
        })
        .pipe(
          map((data: Profile[]) => {
            return data;
          })
        )
    );
  }

  public async findProfileUserNotAssigned(nId: number): Promise<Profile[]> {
    const headers = await this.genericService.getHeaders();
    const params = this.genericService.getParams({ userId: nId });

    const URL = this.genericService.url_gateway + "/api/profilecontroller/getListProfileUserNotAssigned";

    return lastValueFrom(
      this.http
        .get<Profile[]>(URL, {
          headers: headers,
          params: params,
        })
        .pipe(
          map((data: Profile[]) => {
            return data;
          })
        )
    );
  }
}
