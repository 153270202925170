import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { HomeComponent } from "./components/home/home.component.web";
import { LogoutComponent } from "./components/logout/logout.component.web";
import { PreferenceComponent } from "./components/preference/preference.component";
import { DetailsTaskComponent } from "./components/tasks/details-task/details-task.component";
import { TasksComponent } from "./components/tasks/tasks.component";
import { DirtyFormGuardService } from "./core/dirty-form.guard.service";

const routes: Routes = [
  {
    path: "logout",
    component: LogoutComponent,
  },
  {
    path: "tasks/details-task",
    component: DetailsTaskComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "tasks",
    component: TasksComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "preference",
    component: PreferenceComponent,
    canActivate: [MsalGuard],
    canDeactivate: [DirtyFormGuardService],
  },
  {
    path: "administration",
    data: { breadcrumb: { skip: true } },
    loadChildren: () =>
      import("./components/administration/administration.module.web").then(
        (m) => m.AdministrationModuleWeb
      ),
  },
  {
    path: "",
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "**",
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
  providers: [DirtyFormGuardService],
})
export class AppRoutingModuleWeb {}
