import { Pipe, PipeTransform } from "@angular/core";
import { ScriptService } from "../provider/script.service";

@Pipe({ name: "checkScripts" })
export class CheckScriptsPipe implements PipeTransform {
  constructor(private scriptService: ScriptService) {}
  transform(value: string, status: any) {
    if (value != "") {
      this.reinsertScripts(value, status);
    }
    return value;
  }

  reinsertScripts(value: string, status: string) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(value, "text/html");
    const scripts = <HTMLScriptElement[]>(
      (<unknown>doc.getElementsByTagName("script"))
    );
    if (scripts.length > 0) {
      const scritpsSrc = Array.from(scripts).filter((s) => s.src);
      this.scriptService
        .load(scritpsSrc)
        .then(() => {
          this.scriptService.loadNotSrcScript(status);
        })
        .catch((error) => console.log(error));
    }
  }
}
