import { Injectable } from "@angular/core";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { GenericService } from "./generic.service";
import { TaskGrid } from "../models/grid/task-grid";
import { DataMenuGrid } from "../models/grid/data-menu-grid";
import { Task } from "../models/task";
import { TParam } from "src/app/models/tParam";
import { DatePipe } from "@angular/common";
import { RenderStatus } from "../models/render-status";

@Injectable({ providedIn: "root" })
export class TaskService {
  private customSubject = new Subject<any>();
  private tasks: TaskGrid[] = [];
  private nameCat: string = "All tasks";
  private taskUnit: TaskGrid;
  constructor(private http: HttpClient, private gs: GenericService) {}

  public async getById(nId: number): Promise<Task> {
    const headers = await this.gs.getHeaders();
    const params = this.gs.getParams({ id: nId });
    const URL = this.gs.url_gateway + "/api/taskController/getById";
    return firstValueFrom(
      this.http
        .get(URL, {
          headers: headers,
          params: params,
        })
        .pipe((data: any) => {
          return data;
        }, catchError(this.gs.handleError()))
    );
  }

  public async getList() {
    const headers = await this.gs.getHeaders();
    const URL = this.gs.url_gateway + "/api/taskController/getList";
    return this.http
      .get(URL, {
        headers: headers,
      })
      .pipe(
        map((data: TaskGrid[]) => {
          return data;
        })
      );
  }

  public async getCategories() {
    const headers = await this.gs.getHeaders();
    const URL = this.gs.url_gateway + "/api/taskController/getCategories";
    return this.http
      .get(URL, {
        headers: headers,
      })
      .pipe(
        map((data: DataMenuGrid) => {
          return data;
        })
      );
  }

  public getRenderTask(headers, nId: number) {
    const params = this.gs.getParams({ id: nId });
    const URL = this.gs.url_gateway + "/api/taskController/getRenderTask";

    const getTask$: Observable<RenderStatus> = this.http
      .get<RenderStatus>(URL, {
        headers: headers,
        params: params,
      })
      .pipe(catchError(this.gs.handleError()));
    return getTask$;
  }

  public async getDocument(url: string) {
    const param = url.split("downloadFile")[1];
    const headers = await this.gs.getHeaders();
    const URL = this.gs.url_gateway + "/api/taskController/downloadFile" + param;
    return this.http
      .get(URL, {
        headers: headers,
        responseType: "blob",
        observe: "response",
      })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  public async performActionDetail(nId: number, paramts: TParam[], externalId: string, code: number) {
    const headers = await this.gs.getHeaders();
    const params = this.gs.getParams({
      id: nId,
      externalId: externalId,
      code: code,
    });
    const URL = this.gs.url_gateway + "/api/taskController/performActionDetail";
    return this.http
      .post(URL, paramts, {
        headers: headers,
        responseType: "text",
        params: params,
      })
      .pipe((data) => {
        return data;
      });
  }

  public async performActionList(nId: number, xpathAction: string, externalId: string) {
    const headers = await this.gs.getHeaders();
    const params = this.gs.getParams({
      id: nId,
      xpath: xpathAction,
      externalId: externalId,
    });
    const URL = this.gs.url_gateway + "/api/taskController/performActionList";
    return this.http
      .get(URL, {
        headers: headers,
        responseType: "text",
        params: params,
      })
      .pipe((data) => {
        console.log(data);
        return data;
      });
  }

  setTask(tasks) {
    this.tasks = tasks;
  }

  getTask() {
    return this.tasks;
  }

  setNameCat(nameCat) {
    this.nameCat = nameCat;
  }

  getNameCat() {
    return this.nameCat;
  }

  setTaskUnit(taskUnit) {
    this.taskUnit = taskUnit;
  }

  getTaskUnit() {
    return this.taskUnit;
  }

  public async sendMailFromButtom(taskHtml: string, to: string[], attachments: boolean, subject: string, observations: string, attachHeader: string): Promise<Observable<string>> {
    const headers = await this.gs.getHeaders();
    const URL = this.gs.url_gateway + "/api/emailController/sendMail";
    return this.http
      .post(URL, taskHtml, {
        headers: headers,
        responseType: "text",
        params: this.gs.getParams({
          to: to,
          attachments: attachments,
          subject: subject,
          observations: observations,
          attachHeader: attachHeader,
        }),
      })

      .pipe(
        map((data: string) => {
          return data;
        })
      );
  }

  generateParams(xpathFocus: string, prms: TParam[], id: number) {
    let doc = document.getElementById("detailsTask");
    let params: TParam[] = [];
    let radioGroups = new Map<string, any[]>();

    //input
    let input = doc.getElementsByTagName("input");
    this.inputParams(input, doc, params, radioGroups, prms, id);

    //checkRadioGroups
    this.checkRadioGroups(radioGroups, doc, params);

    //Selects
    let select = doc.getElementsByTagName("select");
    this.selectParams(select, doc, params);

    //textArea
    let tArea = doc.getElementsByTagName("textarea");
    this.textAreaParams(tArea, doc, params, xpathFocus);

    return params;
  }

  private inputParams(input: HTMLCollectionOf<HTMLInputElement>, doc: HTMLElement, params: TParam[], radioGroups: Map<string, any[]>, prms: TParam[], id: number) {
    for (let i = 0; i < input.length; i++) {
      let element = input[i];
      if (element.name.indexOf("__") > 0) {
        element.name = element.name.replaceAll("__", ".");
      }
      if (!element.disabled) {
        if (element.type === "checkbox") {
          this.inputCheckboxElem(element, doc, params, prms, id);
        } else if (element.type == "radio") {
          this.inputRadioElem(element, radioGroups, params, prms, id);
        } else if (element.type === "text" && element.value !== "" && element.id !== "datepicker") {
          this.inputTextElem(element, params);
        } else if (element.id === "datepicker") {
          this.inputDatePickerElem(element, params);
        }
      }
      if (element.name.indexOf(".") > 0 && element.tabIndex > 0) {
        let name = element.name;
        element.name = name.replaceAll("\\.", "__");
      }
    }
  }

  private inputRadioElem(element: HTMLInputElement, radioGroups: Map<string, any[]>, params: TParam[], prms: TParam[], id: number) {
    let key = element.name;
    let list = [];
    if (radioGroups.has(key)) {
      list = radioGroups.get(key);
    }
    list.push(element);
    radioGroups.set(key, list);
    if (element.checked) {
      if (id === 1) {
        let param: TParam = new TParam();
        param.xpath = element.name;
        param.value = element.value;
        params.push(param);
        for (let i = 0; i < prms.length; i++) {
          let param: TParam = new TParam();
          param.xpath = prms[i].xpath;
          param.value = prms[i].value;
          params.push(param);
        }
      } else {
        for (let i = 0; i < prms.length; i++) {
          let param: TParam = new TParam();
          param.xpath = prms[i].xpath;
          param.value = prms[i].value;
          params.push(param);
        }
      }
    }
  }

  private inputTextElem(element: HTMLInputElement, params: TParam[]) {
    let param: TParam = new TParam();
    param.xpath = element.name;
    if (element.name != null && element.name !== "") {
      let value = element.value;
      if (value != null && element.value !== "") {
        param.value = element.value;
        params.push(param);
      }
    }
  }

  private getLabel(name: string, labels: HTMLCollectionOf<HTMLLabelElement>) {
    let label = "";
    for (let i = 0; i < labels.length; i++) {
      let element = labels[i];
      let forTag = element.getAttribute("for");
      if (name === forTag) {
        return element.innerText;
      }
    }
    return label;
  }

  private textAreaParams(tArea: HTMLCollectionOf<HTMLTextAreaElement>, doc: HTMLElement, params: TParam[], xpathFocus: string) {
    for (let i = 0; i < tArea.length; i++) {
      let element = tArea[i];
      if (element.hasAttribute("required")) {
        if (!element.value && xpathFocus != null) {
          let label = this.getLabel(element.name, doc.getElementsByTagName("label"));
          let param: TParam = new TParam();
          param.xpath = "Error";
          param.value = label;
          params.push(param);
          return params;
        }
      } else {
        if (xpathFocus != null && element.name === xpathFocus) {
          let label = this.getLabel(element.name, doc.getElementsByTagName("label"));
          let param: TParam = new TParam();
          param.xpath = "Error";
          param.value = label;
          params.push(param);
          return params;
        }
      }

      if (tArea[i].value) {
        let param: TParam = new TParam();
        param.xpath = tArea[i].name;
        param.value = tArea[i].value;
        params.push(param);
      }
    }
  }

  private selectParams(select: HTMLCollectionOf<HTMLSelectElement>, doc: HTMLElement, params: TParam[]) {
    for (let i = 0; i < select.length; i++) {
      let element = select[i];
      let classes = element.parentElement.parentElement.getAttribute("class");
      if (classes != null && classes === "") {
        element.className;
      }

      if (element.hasAttribute("required") && !element.disabled) {
        if (element.value !== "-1" && element.value !== "") {
          let param: TParam = new TParam();
          param.xpath = element.name;
          param.value = element.value;
          params.push(param);
        } else {
          let label = this.getLabel(element.name, doc.getElementsByTagName("label"));
          let param: TParam = new TParam();
          param.xpath = "Error";
          param.value = label;
          params.push(param);
          return params;
        }
      }
    }
  }

  private checkRadioGroups(radioGroups: Map<string, any[]>, doc: HTMLElement, params: TParam[]) {
    let tablElementsRequired = [];
    this.getTablElementsRequired(doc, tablElementsRequired);
    radioGroups.forEach((key) => {
      let list = [];
      list.push(key);
      let isOneChecked = false;
      let label = null;
      let isRequired = false;
      let isRequiredTable = false;
      for (let i = 0; i < list[0].length; i++) {
        let element = list[0][i];
        if (label === null) {
          label = this.getLabel(element.name, doc.getElementsByTagName("label"));
        }
        isRequired = element.hasAttribute("required");
        if (tablElementsRequired.length != 0) {
          isRequiredTable = this.checkTableRequired(tablElementsRequired, element, isRequiredTable);
        }
        if (element.checked) {
          isOneChecked = true;
          break;
        }
      }
      if (!isOneChecked && (isRequired || isRequiredTable)) {
        let param: TParam = new TParam();
        param.xpath = "Error";
        param.value = label;
        params.push(param);
        return params;
      }
    });
  }

  private inputDatePickerElem(element: HTMLInputElement, params: TParam[]) {
    let param: TParam = new TParam();
    param.xpath = element.name;
    let value = element.value;

    if (value != null && value !== "") {
      const datepipe: DatePipe = new DatePipe("en-US");
      let formattedDate = datepipe.transform("dd/MM/yyyy", "yyyy-MM-dd'T'HH:mm:ssZ");
      param.value = formattedDate;
      params.push(param);
    }
  }

  private inputCheckboxElem(element: HTMLInputElement, doc: HTMLElement, params: TParam[], prms: TParam[], id: number) {
    if (element.checked) {
      if (id === 1) {
        let param: TParam = new TParam();
        param.xpath = element.name;
        param.value = "1";
        params.push(param);
      } else {
        for (let i = 0; i < prms.length; i++) {
          if (element.name === prms[i].xpath) {
            let param: TParam = new TParam();
            param.xpath = element.name;
            param.value = "1";
            params.push(param);
          }
        }
      }
    } else if (element.hasAttribute("required")) {
      let label = this.getLabel(element.name, doc.getElementsByTagName("label"));
      let param: TParam = new TParam();
      param.xpath = "Error";
      param.value = label;
      params.push(param);
      return params;
    }
  }

  public async deleteTasks(ids: number[]): Promise<Observable<string>> {
    const headers = await this.gs.getHeaders();
    const URL = this.gs.url_gateway + "/api/taskController/deleteTasks";

    return this.http
      .post(URL, ids, {
        headers: headers,
      })
      .pipe(
        map((data: string) => {
          return data;
        })
      );
  }

  isDisable(status: string) {
    return document.getElementById("mensajeTareaNoCompatible") || status == "COMPLETED" || status == "IN_PROGRESS" || status == "OBSOLETE";
  }

  disableTask(): void {
    let doc = document.getElementById("detailsTask");
    var elems = doc.querySelectorAll("input,select,textarea");
    for (let i = 0; elems[i]; i++) {
      elems[i].setAttribute("disabled", "true");
    }
  }

  getObservable(): Subject<any> {
    return this.customSubject;
  }
  publishEvent(event: string) {
    this.publishCustomEvent({
      name: event,
    });
  }

  publishCustomEvent(data: any) {
    this.customSubject.next(data);
  }

  private checkTableRequired(tablElementsRequired: any[], element: any, isRequiredTable: boolean) {
    for (let k = 0; k < tablElementsRequired.length; k++) {
      let elementsTable = tablElementsRequired[k];
      for (let x = 0; x < elementsTable.length; x++) {
        if (elementsTable[x].id == element.id) {
          return true;
        }
      }
    }
    return isRequiredTable;
  }

  private getTablElementsRequired(doc: HTMLElement, tablElementsRequired: any[]) {
    let tablElement = doc.getElementsByClassName("valueTablet");
    for (let j = 0; j < tablElement.length; j++) {
      if (tablElement[j].hasAttribute("required")) {
        tablElementsRequired.push(tablElement[j].getElementsByTagName("input"));
      }
    }
  }
}
