import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Preference } from "../models/preference";
import { GenericService } from "./generic.service";
import { interval, lastValueFrom, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { GenericResponse } from "../models/response/generic.response";
import { CategoryService } from "src/app/provider/categoryservice";
import { Constants } from "src/app/core/constants";
import { CategoryMenuGrid } from "../models/grid/category-menu-grid";
import { Network } from "@capacitor/network";
import { MonitoringService } from "./monitoring.service";

@Injectable({
  providedIn: "root",
})
export class PreferenceService {
  private synchronizeSubscription: Subscription = new Subscription();
  source: any;
  constructor(private http: HttpClient, private genericService: GenericService, private categoryService: CategoryService, private router: Router, private mon: MonitoringService) {}

  public async findPreferenceByUser(Ref: boolean): Promise<Preference> {
    const URL = this.genericService.url_gateway + "/api/preferenceController/getPreferenceByUser";
    const headers = await this.genericService.getHeaders();
    return lastValueFrom(
      this.http
        .get<Preference>(URL, {
          headers: headers,
        })
        .pipe(
          map((data: Preference) => {
            if (Ref) {
              if (this.synchronizeSubscription) {
                this.synchronizeSubscription.unsubscribe();
              }
              clearInterval(this.source);
              if (data != null && data.automaticRefresh !== null && data.automaticRefresh !== 0) {
                this.source = interval(30000);
                this.synchronizeSubscription = this.source.subscribe(() => this.synchronize(data.automaticRefresh));
              }
            }
            return data;
          }),
          catchError(this.genericService.handleError())
        )
    );
  }

  public async save(item: Preference): Promise<GenericResponse> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/preferenceController/saverOrUpdate";
    return lastValueFrom(
      this.http
        .post<GenericResponse>(URL, JSON.stringify(item), {
          headers: headers,
        })
        .pipe(
          map((data: GenericResponse) => {
            return data;
          }),
          catchError(this.genericService.handleError())
        )
    );
  }

  public async getProcessList(): Promise<CategoryMenuGrid[]> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/preferenceController/getProcessList";
    return lastValueFrom(
      this.http
        .get<CategoryMenuGrid[]>(URL, {
          headers: headers,
        })
        .pipe(
          map((data: CategoryMenuGrid[]) => {
            console.log(data);
            return data;
          })
        )
    );
  }

  async synchronize(automaticRefresh: number) {
    let refreshTimeString = localStorage.getItem("refreshTime");
    let statusNet = await Network.getStatus();
    this.mon.logEvent("IWF3 STATUS NETWORK", {
      statusNet: statusNet,
    });
    if (refreshTimeString) {
      let refactorRefreshTime = refreshTimeString.replaceAll('"', "");
      let refreshTime = new Date(refactorRefreshTime);
      let ActualDate = new Date();
      refreshTime.setMinutes(refreshTime.getMinutes() + automaticRefresh);
      if ((refreshTime == null || refreshTime <= ActualDate) && statusNet.connected === true) {
        if (this.router.url != Constants.urls.listTasks) {
          this.categoryService.publishEvent("user_cat_back");
        } else {
          this.categoryService.publishEvent("user_cat");
        }
      }
    }
  }
}
