import { Profile } from "./profile";

export class User {
  id: number;
  deleted: Boolean = false;
  userLdap: string = "";
  name: string = "";
  surname: string = "";
  fullName: string = "";
  mail: string = "";
  profilesUser: Profile[] = [];

  constructor(item?: any) {
    this.id = item && item.id ? item.id : 0;
    this.deleted = item && item.deleted ? item.deleted : false;
    this.userLdap = item && item.userLdap ? item.userLdap : "";
    this.name = item && item.name ? item.name : "";
    this.surname = item && item.surname ? item.surname : "";
    this.fullName = this.getCompleteName();
    this.mail = item && item.mail ? item.mail : "";
    this.profilesUser =
      item && item.profilesUser && Array.isArray(item.profilesUser)
        ? item.profilesUser.map((x) => new Profile(x))
        : [];
  }

  public getCompleteName() {
    return this.surname + ", " + this.name;
  }
}
