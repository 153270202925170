<div class="container">
  <div mat-dialog-content>
    <h1 *ngIf="msj === 'MINOR'">{{'comun.available'| translate}}</h1>
    <h1 *ngIf="msj === 'BLOCKING'">{{'comun.needed'| translate}}</h1>
    <label *ngIf="msj === 'MINOR'">{{'comun.updateMinor'| translate}}</label>
    <label *ngIf="msj === 'BLOCKING'">{{'comun.updateBlocking'| translate}}</label>
  </div>

  <div class="footer" mat-dialog-footer *ngIf="msj === 'MINOR'">
    <ion-button color="primary" (click)="close()">
      {{'comun.close'| translate}}
    </ion-button>
  </div>
</div>