<ion-grid no-margin>
  <ion-row>
    <ion-col size="3" (click)="selectColor(i)" *ngFor="let item of colorList; let i = index">
      <fa-icon [style.color]="item.value" icon="square" class="f-s-30"></fa-icon>
    </ion-col>
    <ion-col size="3" (click)="selectColor(null)">
      <fa-icon icon="times" class="c-b f-s-30"></fa-icon>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col size="2" class="p-0-p-l-10">
      <ion-checkbox class="b-c-g" (click)="isChecked($event)"></ion-checkbox>
    </ion-col>
    <ion-col size="10">
      <ion-label> {{ "preference.checkColors" | translate }} </ion-label>
    </ion-col>
  </ion-row>
</ion-grid>
