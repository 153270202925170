import { ModuleOperation } from "./moduleOperation";

export class Profile {
  id: number;
  description: string;
  isDefault: boolean;
  moduleOperations: ModuleOperation[] = [];

  constructor(item?: any) {
    this.id = item && item.id ? item.id : 0;
    this.description = item && item.description ? item.description : "";
    this.isDefault = item && item.isDefault ? item.isDefault : false;
    /*
      this.moduleOperations = item && item.profiles && Array.isArray(item.moduleOperations) ? item.content.map((x:ModuleOperation)=> new Profile({}))  : [];
      */
  }
}
