import { Component, OnDestroy } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Constants } from "src/app/core/constants";
import { CategoryMenuGrid } from "src/app/models/grid/category-menu-grid";
import { DataMenuGrid } from "src/app/models/grid/data-menu-grid";
import { TaskGrid } from "src/app/models/grid/task-grid";
import { CategoryService } from "src/app/provider/categoryservice";
import { GenericService } from "src/app/provider/generic.service";
import { MonitoringService } from "src/app/provider/monitoring.service";
import { TaskService } from "src/app/provider/task.service";

@Component({
  selector: "app-category-menu",
  templateUrl: "./category-menu.component.html",
})
export class CategoryMenuComponent implements OnDestroy {
  taskOpen: boolean = false;
  isBack: boolean = false;
  tasks: TaskGrid[] = [];
  totalTasks: number = 0;
  totalTasksRead: number = 0;
  catMenu: CategoryMenuGrid[] = [];
  private loadingCatSubscription: Subscription;
  private taskReadSubscription: Subscription;

  constructor(private router: Router, private genericService: GenericService, private translateService: TranslateService, private categoryService: CategoryService, private taskService: TaskService, private mon: MonitoringService) {
    this.mon.logPageView("CATEGORY MENU");
    this.loadingCatSubscription = categoryService.getObservable().subscribe((result) => {
      if (result && (result.name === "user_cat" || result.name === "user_cat_back")) {
        this.mon.logEvent("IWF3 EVENT CATOBS", result);
        this.isBack = false;
        if (result.name === "user_cat_back") {
          this.isBack = true;
        }
        this.getData();
      }
    });

    this.taskReadSubscription = taskService.getObservable().subscribe((result) => {
      this.mon.logEvent("IWF3 EVENT TASKOBS", result);
      if (result && result.name === "reading_task") {
        this.readTask();
      }
    });
  }

  private readTask() {
    var task = this.taskService.getTaskUnit();
    if (task != null && this.catMenu != null) {
      this.totalTasksRead = this.totalTasksRead - 1;
      var cat1 = this.catMenu.find((obj) => obj.categoryId === task.catId1);
      if (cat1 != null) {
        let index1 = this.catMenu.indexOf(cat1);
        cat1.counTaskRead = cat1.counTaskRead - 1;
        var cat2 = cat1.catMenu?.find((obj) => obj.categoryId === task.catId2);
        if (cat2 != null) {
          var cat3 = cat2.catMenu?.find((obj) => obj.categoryId === task.catId3);
          let index2 = this.catMenu?.indexOf(cat2);
          cat2.counTaskRead = cat2.counTaskRead - 1;
          if (cat3 != null) {
            let index3 = this.catMenu?.indexOf(cat3);
            cat3.counTaskRead = cat3.counTaskRead - 1;
            cat2.catMenu[index3] = cat3;
          }
          cat1.catMenu[index2] = cat2;
        }
        this.catMenu[index1] = cat1;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.taskReadSubscription) {
      this.taskReadSubscription.unsubscribe();
    }
    if (this.loadingCatSubscription) {
      this.loadingCatSubscription.unsubscribe();
    }
  }

  private async getData() {
    if (this.genericService.getCatLoadFlag() && this.genericService.getTaskLoadFlag()) {
      this.mon.logEvent("IWF3 CATEGORY GET DATA");
      this.totalTasks = 0;
      this.totalTasksRead = 0;
      this.catMenu = [];
      this.tasks = [];
      this.genericService.setCatLoadFlag(false);
      this.genericService.setTaskLoadFlag(false);
      if (!this.isBack) {
        this.genericService.publishEvent("activete_loading");
      }
      this.openTasks(localStorage.getItem("catId1"), localStorage.getItem("catId2"), localStorage.getItem("catId3"), localStorage.getItem("name"), localStorage.getItem("system"));

      (await this.taskService.getCategories()).subscribe({
        next: (data: DataMenuGrid) => {
          this.mon.logEvent("IWF3 CATEGORY GET DATA getCategories response", data);
          this.totalTasks = data.totalCounTask;
          this.totalTasksRead = data.totalCounTaskRead;
          this.catMenu = data.catMenu;
          this.genericService.setCatLoadFlag(true);
          if (this.genericService.getTaskLoadFlag() && this.genericService.getMenuLoadFlag()) {
            this.categoryService.publishEvent("refresh_time");
            if (!this.isBack) {
              this.genericService.publishEvent("close_loading");
            }
            if (!this.genericService.getOpenTaskFromPush() && !this.isBack) {
              const navigationExtras: NavigationExtras = {
                state: { fromUserCat: true },
              };
              this.router.navigate([Constants.urls.listTasks], navigationExtras);
            }
            this.isBack = false;
          }
        },
        error: (error) => {
          this.mon.logEvent("IWF3 CATEGORY GET DATA getCategories error");
          this.mon.logException(error);
          this.genericService.setCatLoadFlag(true);
          this.genericService.setTaskLoadFlag(true);
          if (!this.isBack) {
            this.genericService.publishEvent("close_loading");
          }
          this.isBack = false;
          this.categoryService.publishEvent("refresh_error");
          this.genericService.showMessage(this.translateService.instant("comun.errorCatLoad"));
        },
      });
    }
  }

  async openTasks(catId1: string, catId2: string, catId3: string, name: string, system: string) {
    let cat1 = catId1 || "";
    let cat2 = catId2 || "";
    let cat3 = catId3 || "";
    let nameTask = name || "All Task";

    this.mon.logTrace("IWF3 OPENTASK", {
      catId1: cat1,
      catId2: cat2,
      catId3: cat3,
      name: nameTask,
    });

    if (this.tasks.length === 0) {
      (await this.taskService.getList()).subscribe({
        next: (listTask: TaskGrid[]) => {
          this.tasks = listTask;
          this.filterListTask(cat1, cat2, cat3, nameTask, system);
        },
        error: () => {
          this.genericService.setCatLoadFlag(true);
          this.genericService.setTaskLoadFlag(true);
          if (!this.isBack) {
            this.genericService.publishEvent("close_loading");
          }
          this.isBack = false;
          this.categoryService.publishEvent("refresh_error");
        },
      });
    } else {
      this.filterListTask(cat1, cat2, cat3, nameTask, system);
    }
  }

  private filterListTask(catId1: string, catId2: string, catId3: string, name: string, system: string) {
    let taskListFilter = this.tasks.filter((f) => ((f.catId1 == catId1 && f.system == system) || catId1 == "") && ((f.catId2 == catId2 && f.system == system) || catId2 == "") && ((f.catId3 == catId3 && f.system == system) || catId3 == ""));

    var catName = name;
    if (catId1 == "" && catId2 == "" && catId3 == "") {
      catName = this.translateService.instant("task.allTask");
    }
    localStorage.setItem("catId1", catId1);
    localStorage.setItem("catId2", catId2);
    localStorage.setItem("catId3", catId3);
    localStorage.setItem("name", name);
    localStorage.setItem("system", system);
    this.taskService.setTask(taskListFilter);
    this.taskService.setNameCat(catName);
    this.genericService.setTaskLoadFlag(true);
    if (this.genericService.getCatLoadFlag() && this.genericService.getMenuLoadFlag()) {
      this.categoryService.publishEvent("refresh_time");
      if (!this.isBack) {
        this.genericService.publishEvent("close_loading");
      }
      if (!this.genericService.getOpenTaskFromPush() && !this.isBack) {
        const navigationExtras: NavigationExtras = {
          state: { fromUserCat: true },
        };
        this.router.navigate([Constants.urls.listTasks], navigationExtras);
      }
      this.isBack = false;
    }
  }
}
