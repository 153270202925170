import { User } from "./user";
import { CategoryMenuGrid } from "src/app/models/grid/category-menu-grid";

export class Preference {
  id: number;
  language: string;
  completedTasksDwellTime: number;
  failedTasksDwellTime: number;
  automaticRefresh: number;
  user: User;
  catMenu: CategoryMenuGrid[];
  fontSize: number;

  constructor(item?: any) {
    this.id = item && item.id ? item.id : 0;
    this.language = item && item.language ? item.language : "es";
    this.completedTasksDwellTime = item && item.completedTasksDwellTime ? item.completedTasksDwellTime : 0;
    this.failedTasksDwellTime = item && item.failedTasksDwellTime != null ? item.failedTasksDwellTime : 1440;
    this.automaticRefresh = item && item.automaticRefresh ? item.automaticRefresh : 0;
    this.user = item && item.user ? new User(item.user) : new User();
    this.catMenu = item && item.catMenu ? item.catMenu : [];
    this.fontSize = item && item.fontSize ? item.fontSize : 2;
  }
}
