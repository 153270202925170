import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Color } from "src/app/models/color";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-modal-color-picker",
  templateUrl: "./modal-color-picker.component.html",
})
export class ModalColorPickerComponent {
  colorList: Color[] = [
    { name: "Azul", value: "#03618e", checked: false },
    { name: "Rojo", value: "#FF0000", checked: false },
    { name: "Verde", value: "#00FF00", checked: false },
    { name: "Amarillo", value: "#FFFF00", checked: false },
    { name: "Magenta", value: "#FF00FF", checked: false },
    { name: "Cian", value: "#00FFFF", checked: false },
    { name: "Naranja", value: "#FFA500", checked: false },
    { name: "Rosa", value: "#FFC0CB", checked: false },
    { name: "Púrpura", value: "#800080", checked: false },
    { name: "Marrón", value: "#A52A2A", checked: false },
    { name: "Verde claro", value: "#90EE90", checked: false },
    { name: "Azul cielo", value: "#87CEEB", checked: false },
    { name: "Gris Oscuro", value: "#828282", checked: false },
    { name: "Dorado", value: "#FFD700", checked: false },
    { name: "Verde oliva", value: "#808000", checked: false },
  ];

  selectedColor: Color = new Color();
  checked: boolean = false;

  constructor(
    public modalController: ModalController,
    private dialogRef: MatDialogRef<ModalColorPickerComponent>
  ) {}

  selectColor(i: number) {
    if (i != null) {
      this.selectedColor = this.colorList[i];
    }
    if (this.checked == true) {
      this.selectedColor.checked = true;
    }
    this.dialogRef.close(this.selectedColor);
  }

  isChecked(event) {
    if (!event.currentTarget.checked) {
      this.checked = true;
    } else {
      this.checked = false;
    }
  }
}
