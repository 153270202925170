<div *ngFor="let module_level1 of modules; let i = index">
  <ion-item detail="false" color="light" *ngIf="module_level1.iconResource !== null" (click)="module_level1.open = !module_level1.open; goToUrl(module_level1.id, module_level1.url, $event)" lines="full" button="true" class="b-s-n">
    <ion-icon class="m-r-10" slot="start" name="{{ module_level1.iconResource }}"></ion-icon>
    <ion-label>
      <p class="menulist">{{ module_level1.i18n | translate }}</p>
    </ion-label>
    <div *ngIf="module_level1.modules.length > 0" class="min-w-20">
      <ion-icon name="chevron-down" slot="end" *ngIf="!module_level1.open"></ion-icon>
      <ion-icon name="chevron-up" size="small" slot="end" *ngIf="module_level1.open"></ion-icon>
    </div>
  </ion-item>
  <div *ngIf="module_level1.open && module_level1.modules.length > 0">
    <div *ngFor="let module_level2 of module_level1.modules; let i2 = index">
      <ion-item (click)="module_level2.open = !module_level2.open; goToUrl(module_level2.id, module_level2.url, $event)" lines="none" detail="false" button="true" class="b-s-n">
        <ion-icon class="m-r-10 p-l-20" slot="start" name="{{ module_level2.iconResource }}"></ion-icon>
        <ion-label>
          <p class="menulist">{{ module_level2.i18n | translate }}</p>
        </ion-label>
        <div *ngIf="module_level2.modules.length > 0">
          <ion-icon class="m-r-10" name="arrow-down-circle" size="small" slot="end" *ngIf="!module_level2.open"> </ion-icon>
          <ion-icon class="m-r-10" name="remove" size="small" slot="end" *ngIf="module_level2.open"></ion-icon>
        </div>
      </ion-item>
      <div *ngIf="module_level2.open && module_level2.modules.length > 0">
        <ion-menu-toggle *ngFor="let module_level3 of module_level2.modules; let i3 = index">
          <ion-item color="contrast" (click)="goToUrl(module_level3.id, module_level3.url, $event)" lines="none" detail="false" button="true" class="b-s-n">
            <ion-icon class="m-r-10" slot="start" name="{{ module_level3.iconResource }}"></ion-icon>
            <ion-label>
              <p class="menulist">{{ module_level3.i18n | translate }}</p>
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </div>
    </div>
  </div>
</div>
