import { Injectable } from "@angular/core";
import {Location} from '@angular/common';
import { Observable, Observer } from "rxjs";
import { CanDeactivate, Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmModalComponent } from "../components/shared/confirm-modal/confirm-modal.component";
 
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
 
@Injectable()
export class DirtyFormGuardService implements CanDeactivate<CanComponentDeactivate> {
 
  constructor( 
      public dialog: MatDialog,
      public translateService: TranslateService,
      public location: Location,
      public router: Router ) {

  }

  canDeactivate(component: CanComponentDeactivate) {
    var history: any[]= JSON.parse(localStorage.getItem('history'));
    let back = localStorage.getItem('backPressed');

    if (!component.canDeactivate || component.canDeactivate()) {
      if(back == 'true'){
        history.pop();
        localStorage.setItem('history',JSON.stringify(history));
        localStorage.removeItem('backPressed');
      }
      return true;
    }

    return new Observable((observer: Observer<boolean>) => {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {width: '600px'});
      let instance = dialogRef.componentInstance;
      instance.title = this.translateService.instant('validation.questionconfirm');
      instance.txt = this.translateService.instant('validation.loseDataForm');
      instance.btnPrimary= this.translateService.instant('comun.confirm');

      dialogRef.afterClosed().subscribe(result => {
        if(result!==undefined && result.close){ 
          if(back == 'true'){
            history.pop();
            localStorage.setItem('history',JSON.stringify(history));
            localStorage.removeItem('backPressed');
          }
          observer.next(true);
          observer.complete();
        } else {
          observer.next(false);
          observer.complete();
        }
        });
    });
  }
}