import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { GenericService } from "./generic.service";
@Injectable({ providedIn: "root" })
export class AppService {
  constructor(private http: HttpClient, private gs: GenericService) {}

  public async findMenu(): Promise<Observable<[]>> {
    const headers = await this.gs.getHeaders();
    const URL = this.gs.url_gateway + "/api/moduleservices/getTreeModule";
    return this.http.get<[]>(URL, { headers: headers });
  }

  public async getURLPermissionByIdUser(): Promise<Observable<[]>> {
    const headers = await this.gs.getHeaders();
    const URL =
      this.gs.url_gateway +
      "/api/profileOperationcontroller/getURLPermissionByIdUser";
    return this.http.get<[]>(URL, { headers: headers });
  }
}
