<ion-item detail="false" (click)="taskOpen = !taskOpen" color="light" lines="full" button="true" class="b-s-n">
  <ion-icon class="m-r-10" slot="start" name="file-tray-full-outline"></ion-icon>
  <ion-menu-toggle auto-hide="false" class="w-f-a">
    <ion-label (click)="openTasks('', '', '', 'All Tasks', '')">
      <p class="menulist">{{ "menu.tasks" | translate }}</p>
    </ion-label>
  </ion-menu-toggle>
  <ion-badge color="medium" *ngIf="totalTasks > 0">{{ totalTasksRead }} </ion-badge>
  <ion-badge class="m-r-5" color="primary" *ngIf="totalTasks > 0">{{ totalTasks }} </ion-badge>
  <div class="min-w-20">
    <ion-icon name="chevron-down" slot="end" *ngIf="!taskOpen"></ion-icon>
    <ion-icon name="chevron-up" slot="end" *ngIf="taskOpen"></ion-icon>
  </div>
</ion-item>
<div *ngIf="taskOpen && catMenu !== null && catMenu.length > 0">
  <div *ngFor="let c0 of catMenu">
    <ion-item detail="false" color="light" (click)="c0.open = !c0.open" button="true" class="b-s-n">
      <ion-menu-toggle auto-hide="false" class="w-f-a">
        <ion-label (click)="openTasks(c0.categoryId, '', '', c0.name, c0.system)">
          <p class="menulist p-l-20" *ngIf="c0.name === ''">-</p>
          <p class="menulist p-l-20" *ngIf="c0.name !== ''">{{ c0.name }}</p>
        </ion-label>
      </ion-menu-toggle>
      <ion-badge color="medium" *ngIf="c0.counTaskRead > 0">{{ c0.counTaskRead }} </ion-badge>
      <ion-badge class="m-r-5" color="primary" *ngIf="c0.counTask > 0">{{ c0.counTask }} </ion-badge>
      <div class="min-w-20">
        <div *ngIf="c0.catMenu !== null && c0.catMenu.length > 0">
          <ion-icon slot="end" name="chevron-down" *ngIf="!c0.open"></ion-icon>
          <ion-icon slot="end" name="chevron-up" *ngIf="c0.open"></ion-icon>
        </div>
      </div>
    </ion-item>
    <div *ngIf="c0.open && c0.catMenu !== null && c0.catMenu.length > 0">
      <div *ngFor="let c1 of c0.catMenu">
        <ion-item detail="false" color="light" (click)="c1.open = !c1.open" button="true" class="b-s-n">
          <ion-menu-toggle auto-hide="false" class="w-f-a">
            <ion-label (click)="c1.open = true; openTasks(c0.categoryId, c1.categoryId, '', c1.name, c1.system)">
              <p class="menulist p-l-30" *ngIf="c1.name === ''">-</p>
              <p class="menulist p-l-30" *ngIf="c1.name !== ''">{{ c1.name }}</p>
            </ion-label>
          </ion-menu-toggle>
          <ion-badge color="medium" *ngIf="c1.counTaskRead > 0"> {{ c1.counTaskRead }}</ion-badge>
          <ion-badge class="m-r-5" color="primary" *ngIf="c1.counTask > 0"> {{ c1.counTask }}</ion-badge>
          <div class="min-w-20">
            <div *ngIf="c1.catMenu !== null && c1.catMenu.length > 0">
              <ion-icon slot="end" name="chevron-down" *ngIf="!c1.open"></ion-icon>
              <ion-icon slot="end" name="chevron-up" *ngIf="c1.open"></ion-icon>
            </div>
          </div>
        </ion-item>
        <div *ngIf="c1.open && c1.catMenu !== null && c1.catMenu.length > 0">
          <div *ngFor="let c2 of c1.catMenu">
            <ion-item detail="false" color="light" button="true" class="b-s-n">
              <ion-menu-toggle auto-hide="false" class="w-f-a">
                <ion-label (click)="openTasks(c0.categoryId, c1.categoryId, c2.categoryId, c2.name, c2.system)">
                  <p class="menulist p-l-40" *ngIf="c2.name === ''">-</p>
                  <p class="menulist p-l-40" *ngIf="c2.name !== ''">{{ c2.name }}</p>
                </ion-label>
              </ion-menu-toggle>
              <ion-badge color="medium" *ngIf="c2.counTaskRead > 0"> {{ c2.counTaskRead }}</ion-badge>
              <ion-badge class="m-r-5" color="primary" *ngIf="c2.counTask > 0"> {{ c2.counTask }}</ion-badge>
              <div class="min-w-20"></div>
            </ion-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
