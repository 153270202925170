export class Color {
  name: string;
  value: string;
  checked: boolean;
  constructor(item?: any) {
    this.name = item && item.name ? item.name : "";
    this.value = item && item.value ? item.value : null;
    this.checked = item && item.checked ? item.checked : false;
  }
}
