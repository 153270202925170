<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title> {{title}} </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col *ngIf="permissionMultiApprove || permissionMultiDelete" size="auto">
        <ion-checkbox color="primary" (ionChange)="selectAll($event)"></ion-checkbox>
      </ion-col>
      <ion-col *ngIf="permissionMultiApprove" size="auto" class="p-0">
        <fa-icon icon="clipboard-check" (click)="approve()" title="{{'task.approve'| translate}}"
          class="fa-icon-list icon-align-justify"></fa-icon>
      </ion-col>
      <ion-col *ngIf="permissionMultiDelete" size="auto" class="p-0">
        <fa-icon icon="trash" (click)="delete()" title="{{'task.delete'|
        translate}}" class="fa-icon-list icon-align-justify"></fa-icon>
      </ion-col>
      <ion-col class="p-0">
        <ion-searchbar placeholder="{{'comun.searchbar'| translate}}" class="p-0-p-l-10 p-r-10" name="filterTasksPost"
          [(ngModel)]="filterTasksPost">
        </ion-searchbar>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <div class="fullheight xc" *ngIf="showNoTasks">
    <div class="vcs">
      <div>
        <ion-icon class="w-h-30" name="logo-buffer"></ion-icon>
      </div>
      <div>
        <ion-text class="m-0 f-s-18">{{'task.noTasks'| translate}}</ion-text>
      </div>
    </div>
  </div>
  <ion-list class="p-0">
    <div [ngClass]="!isIOS ? 'showSlide' : 'd-n'">
      <ion-item *ngFor="let t of listTasks | filterTasks: filterTasksPost | slice:0:slice; let i = index;"
        class="b-s-n p-s-e-0 b-c c-p-t" style="border-left-color: {{t.color}}"
        [ngClass]="(i % 2 == 0) ? 'odd' : 'even' ">
        <ion-grid>
          <ion-row class="f-w-0 ion-align-items-start">

            <ion-col size="auto" class="f-g-0 p-t-0" *ngIf="permissionMultiApprove || permissionMultiDelete">
              <ion-checkbox class="m-t-0 m-b-0"
                *ngIf="t.status == 'CREATED' || t.status == 'FAILED' || t.status == 'ERROR'" color="primary"
                [(ngModel)]="t.isChecked">
              </ion-checkbox>
              <ion-checkbox class="m-t-0 m-b-0 op-0"
                *ngIf="t.status == 'COMPLETED' || t.status == 'IN_PROGRESS' || t.status == 'OBSOLETED'" color="primary"
                [(ngModel)]="t.isChecked">
              </ion-checkbox>
            </ion-col>
            <ion-col class="p-0 f-g-0" *ngIf="!permissionMultiApprove && !permissionMultiDelete">
            </ion-col>
            <ion-col size="auto" class="f-g-0 p-0 m-l-3" (click)="details(t.id)">
              <div *ngIf="t.isRead && t.status == 'CREATED'">
                <ion-icon size="auto" name="mail-open-sharp" class="ion-icon-task c-blue"></ion-icon>
              </div>
              <div *ngIf="!t.isRead && t.status == 'CREATED'">
                <ion-icon size="auto" name="mail-sharp" class="ion-icon-task c-blue"></ion-icon>
              </div>
              <div *ngIf="t.status == 'COMPLETED'">
                <ion-icon size="auto" name="checkmark-circle-sharp" class="ion-icon-task c-green"></ion-icon>
              </div>
              <div *ngIf="t.status == 'OBSOLETE'">
                <ion-icon size="auto" name="checkmark-circle-sharp" class="ion-icon-task c-green"></ion-icon>
              </div>
              <div *ngIf="t.status == 'IN_PROGRESS'">
                <ion-icon size="auto" name="hourglass-sharp" class="ion-icon-task c-yellow"></ion-icon>
              </div>
              <div *ngIf="t.status == 'FAILED'">
                <ion-icon size="auto" name="warning-sharp" class="ion-icon-task c-red"></ion-icon>
              </div>
              <div *ngIf="t.status == 'ERROR'">
                <ion-icon size="auto" name="warning-sharp" class="ion-icon-task c-red"></ion-icon>
              </div>
            </ion-col>
            <ion-col size="auto" class="f-g-0 p-0 p-t-3">
              <fa-icon *ngIf="t.priority == 1" [icon]="['fas', 'exclamation']" class="p-0 m-0 f-s-18 c-red"></fa-icon>
              <fa-icon *ngIf="t.priority == 0" [icon]="['fas', 'exclamation']"
                class="p-0 m-0 f-s-18 c-red op-0"></fa-icon>
            </ion-col>
            <ion-col class="m-t-1" size="10" (click)="details(t.id)">
              <p class="m-0">{{t.name}}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="10" (click)="details(t.id)">
              <p class="m-0"><strong>{{t.subject}}</strong></p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto" (click)="details(t.id)">
              <p class="m-0">{{t.description}}</p>
            </ion-col>
          </ion-row>
          <ion-row class="f-w-0"
            *ngIf="t.status == 'CREATED' || t.status == 'FAILED' || t.status == 'ERROR' || t.status == 'IN_PROGRESS'">
            <ion-col size="auto" (click)="details(t.id)">
              <ion-icon name="calendar-outline" class="ion-icon-task"></ion-icon>
            </ion-col>
            <ion-col class="p-t-10" size="11" (click)="details(t.id)">
              <p class="m-0 f-s-14">{{'comun.mobilized'| translate}}: {{t.creationDate | date:'dd/MM/yyyy'}}
                ({{'comun.assigned'| translate}} {{t.registerDate
                |
                intlRelativeTime: { locale, numeric:'auto' }
                |
                customRelativeTimePipe}})</p>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-button color="primary"
          *ngIf="t.idDefaultQuickOutcome != null && (t.status == 'CREATED' || t.status == 'FAILED')"
          (click)="perfomAction(t.xpath, t.id, t.externalId)">
          <ion-label>
            {{'enum.outcome.' + t.idDefaultQuickOutcome | translate}}
          </ion-label>
        </ion-button>
        <ion-button color="success" (click)=" showSendMail(t.id)">
          <ion-label> {{'comun.send'| translate}} </ion-label>
        </ion-button>
      </ion-item>
    </div>
    <div [ngClass]="isIOS ? '' : 'noShowSlide'">
      <ion-item-sliding *ngFor="let t of listTasks | filterTasks: filterTasksPost | slice:0:slice; let i = index;">
        <ion-item class="b-s-n p-s-e-0 b-c" style="border-left-color: {{t.color}}"
          [ngClass]="(i % 2 == 0) ? 'odd' : 'even'">
          <ion-grid>
            <ion-row class="f-w-0 ion-align-items-start">
              <ion-col size="auto" class="p-t-0 f-g-0" *ngIf="permissionMultiApprove || permissionMultiDelete">
                <ion-checkbox class="m-t-0 m-b-0"
                  *ngIf="t.status == 'CREATED' || t.status == 'FAILED' || t.status == 'ERROR'" color="primary"
                  [(ngModel)]="t.isChecked">
                </ion-checkbox>
                <ion-checkbox class="m-t-0 m-b-0 op-0"
                  *ngIf="t.status == 'COMPLETED' || t.status == 'IN_PROGRESS' || t.status == 'OBSOLETED'"
                  color="primary" [(ngModel)]="t.isChecked">
                </ion-checkbox>
              </ion-col>
              <ion-col class="p-0 f-g-0" *ngIf="!permissionMultiApprove && !permissionMultiDelete">
              </ion-col>
              <ion-col size="auto" class="f-g-0 p-0 m-l-3" (click)="details(t.id)">
                <div *ngIf="t.isRead && t.status == 'CREATED'">
                  <ion-icon size="auto" name="mail-open-sharp" class="ion-icon-task c-blue"></ion-icon>
                </div>
                <div *ngIf="!t.isRead && t.status == 'CREATED'">
                  <ion-icon size="auto" name="mail-sharp" class="ion-icon-task c-blue"></ion-icon>
                </div>
                <div *ngIf="t.status == 'COMPLETED'">
                  <ion-icon size="auto" name="checkmark-circle-sharp" class="ion-icon-task c-green"></ion-icon>
                </div>
                <div *ngIf="t.status == 'OBSOLETE'">
                  <ion-icon size="auto" name="checkmark-circle-sharp" class="ion-icon-task c-green"></ion-icon>
                </div>
                <div *ngIf="t.status == 'IN_PROGRESS'">
                  <ion-icon size="auto" name="hourglass-sharp" class="ion-icon-task c-yellow"></ion-icon>
                </div>
                <div *ngIf="t.status == 'FAILED'">
                  <ion-icon size="auto" name="warning-sharp" class="ion-icon-task c-red"></ion-icon>
                </div>
                <div *ngIf="t.status == 'ERROR'">
                  <ion-icon size="auto" name="warning-sharp" class="ion-icon-task c-red"></ion-icon>
                </div>
              </ion-col>
              <ion-col size="auto" class="f-g-0 p-0 p-t-1">
                <fa-icon *ngIf="t.priority == 1" [icon]="['fas', 'exclamation']" class="p-0 m-0 f-s-18 c-red"></fa-icon>
                <fa-icon *ngIf="t.priority == 0" [icon]="['fas', 'exclamation']"
                  class="p-0 m-0 f-s-18 c-red op-0"></fa-icon>
              </ion-col>
              <ion-col class="m-t-1" size="10" (click)="details(t.id)">
                <p class="m-0">{{t.name}}</p>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="10" (click)="details(t.id)">
                <p class="m-0 p-0"><strong>{{t.subject}}</strong></p>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="auto" (click)="details(t.id)">
                <p class="m-0 p-0">{{t.description}}</p>
              </ion-col>
            </ion-row>
            <ion-row class="f-w-0"
              *ngIf="t.status == 'CREATED' || t.status == 'FAILED' || t.status == 'ERROR' || t.status == 'IN_PROGRESS'">
              <ion-col size="auto" (click)="details(t.id)">
                <ion-icon name="calendar-outline" class="ion-icon-task"></ion-icon>
              </ion-col>
              <ion-col class="p-t-10" size="11" (click)="details(t.id)">
                <p class="m-0 f-s-14">{{'comun.mobilized'| translate}}: {{t.creationDate | date:'dd/MM/yyyy'}}
                  ({{'comun.assigned'| translate}} {{t.registerDate
                  |
                  intlRelativeTime: { locale, numeric:'auto' }
                  |
                  customRelativeTimePipe}})</p>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-item-options side="start"
          *ngIf="t.idDefaultQuickOutcome != null && (t.status == 'CREATED' || t.status == 'FAILED')"
          (ionSwipe)="perfomAction(t.xpath, t.id, t.externalId)">
          <ion-item-option expandable color="primary" (click)="perfomAction(t.xpath, t.id, t.externalId)">
            <ion-label>
              {{'enum.outcome.' + t.idDefaultQuickOutcome | translate}}
            </ion-label>
          </ion-item-option>
        </ion-item-options>
        <ion-item-options side="end" (ionSwipe)="showSendMail(t.id)">
          <ion-item-option expandable color="success" (click)="showSendMail(t.id)">
            <ion-label> {{'comun.send'| translate}} </ion-label>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </div>
  </ion-list>
  <ion-infinite-scroll threshold="100px" (ionInfinite)="doInfinite($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>