class Enum {
  id: any;
  translate: "";
  class: "";
  constructor(item?: any) {
    this.id = item && item.id ? item.id : 0;
    this.translate = item && item.translate ? item.translate : "";
    this.class = item && item.class ? item.class : "";
  }
}

export class Constants {
  constructor() {}

  public static urls = {
    home: "/",
    listUser: "/administration/users",
    editUser: "/administration/users/editUsuario",
    listRol: "/administration/roles",
    editRol: "/administration/roles/editRol",
    listTasks: "/tasks",
    detailsTask: "/tasks/details-task",
  };

  public static enums = {
    expedientStatus: [
      new Enum({ id: "OPEN", translate: "enum.expedientStatus.OPEN" }),
      new Enum({ id: "AWAITING", translate: "enum.expedientStatus.AWAITING" }),
      new Enum({ id: "CLOSED", translate: "enum.expedientStatus.CLOSED" }),
    ],
    expedientSuppliedStatus: [
      new Enum({
        id: "REGISTERED",
        translate: "enum.expedientSuppliedStatus.REGISTERED",
      }),
      new Enum({ id: "PAID", translate: "enum.expedientSuppliedStatus.PAID" }),
    ],
    sex: [
      new Enum({ id: "0", translate: "enum.sex.male" }),
      new Enum({ id: "1", translate: "enum.sex.female" }),
    ],
    invoiceIssuedStatus: [
      new Enum({ id: "ISSUED", translate: "enum.invoiceIssuedStatus.ISSUED" }),
      new Enum({
        id: "CHARGED",
        translate: "enum.invoiceIssuedStatus.CHARGED",
      }),
      new Enum({ id: "PAID", translate: "enum.invoiceIssuedStatus.PAID" }),
      new Enum({
        id: "RECORDED",
        translate: "enum.invoiceIssuedStatus.RECORDED",
      }),
    ],
    invoiceReceivedStatus: [
      new Enum({
        id: "REGISTERED",
        translate: "enum.expedientSuppliedStatus.REGISTERED",
      }),
      new Enum({ id: "PAID", translate: "enum.expedientSuppliedStatus.PAID" }),
      new Enum({
        id: "RECORDED",
        translate: "enum.invoiceIssuedStatus.RECORDED",
      }),
    ],
    invoiceIssuedType: [
      new Enum({ id: "SINGLE", translate: "enum.invoiceIssuedType.single" }),
      new Enum({
        id: "MULTIPLE",
        translate: "enum.invoiceIssuedType.multiple",
      }),
      new Enum({
        id: "INDEPENDENT",
        translate: "enum.invoiceIssuedType.independent",
      }),
    ],
    provisionStatus: [
      new Enum({
        id: "REGISTERED",
        translate: "enum.provisionStatus.REGISTERED",
      }),
      new Enum({ id: "CHARGED", translate: "enum.provisionStatus.CHARGED" }),
      new Enum({ id: "RETURNED", translate: "enum.provisionStatus.RETURNED" }),
    ],
    profileType: [
      new Enum({
        id: "ADMINISTRATOR",
        translate: "enum.profileType.ADMINISTRATOR",
      }),
      new Enum({ id: "USER", translate: "enum.profileType.USER" }),
    ],
  };
}
