import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PermissionUser } from "../models/permissionUser";
import { GenericService } from "../provider/generic.service";
import { Constants } from "./constants";
@Component({
  selector: "app-page-base",
  template: '<p style="display:none;"></p>',
})
export class GenericPageComponent implements OnInit {
  public permissionRead: boolean = false;
  public permissionExport: boolean = false;
  public permissionWrite: boolean = false;
  public permissionDelete: boolean = false;
  public permissionImpersonate: boolean = false;
  public permissionMultiApprove: boolean = false;
  public permissionMultiDelete: boolean = false;

  constructor(
    public genericService: GenericService,
    public router: Router,
    public translateService: TranslateService
  ) {}
  async ngOnInit(): Promise<void> {
    let perStorage = localStorage.getItem("permissions");
    if (perStorage) {
      let per: PermissionUser[] = JSON.parse(perStorage);
      if (
        per.filter(
          (x) =>
            this.router.url
              .toLocaleLowerCase()
              .indexOf(x.url.toLocaleLowerCase()) != -1 &&
            x.idOperation == "READ"
        ).length > 0
      ) {
        this.permissionRead = true;
      }
      if (
        per.filter(
          (x) =>
            this.router.url
              .toLocaleLowerCase()
              .indexOf(x.url.toLocaleLowerCase()) != -1 &&
            x.idOperation == "WRITE"
        ).length > 0
      ) {
        this.permissionWrite = true;
        this.permissionExport = true;
      }
      if (
        per.filter(
          (x) =>
            this.router.url
              .toLocaleLowerCase()
              .indexOf(x.url.toLocaleLowerCase()) != -1 &&
            x.idOperation == "DELETE"
        ).length > 0
      ) {
        this.permissionDelete = true;
      }
      if (
        per.filter(
          (x) =>
            this.router.url
              .toLocaleLowerCase()
              .indexOf(x.url.toLocaleLowerCase()) != -1 &&
            x.idOperation == "IMPERSONATE"
        ).length > 0
      ) {
        this.permissionImpersonate = true;
      }
      if (
        per.filter(
          (x) =>
            this.router.url
              .toLocaleLowerCase()
              .indexOf(x.url.toLocaleLowerCase()) != -1 &&
            x.idOperation == "MULTIPLE_APPROVE"
        ).length > 0
      ) {
        this.permissionMultiApprove = true;
      }
      if (
        per.filter(
          (x) =>
            this.router.url
              .toLocaleLowerCase()
              .indexOf(x.url.toLocaleLowerCase()) != -1 &&
            x.idOperation == "DELETE" &&
            x.labelPermission == "TASK"
        ).length > 0
      ) {
        this.permissionMultiDelete = true;
      }
      if (!this.isAllow()) {
        this.genericService.showMessage(
          this.translateService.instant("validation.notAllow"),
          "warning"
        );
        this.router.navigated = false;
        this.router.navigate([Constants.urls.home]);
      }
    } else {
      this.router.navigated = false;
      this.router.navigate([Constants.urls.home]);
    }
  }
  public isAllow(): boolean {
    if (this.permissionWrite || this.permissionDelete || this.permissionRead) {
      return true;
    } else {
      return false;
    }
  }
}
