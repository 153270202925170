import { registerPlugin, Plugin } from "@capacitor/core";

interface MSALPluginInterface extends Plugin {
  currectAccount: () => Promise<Record<"account", string>>;
  authorizeAPI: (scope: any) => Promise<Record<"token", string>>;
  loginIOS: () => Promise<Record<"account", string>>;
  logoutIOS: () => Promise<Record<"token", string>>;
}

export const MSALPlugin = registerPlugin<MSALPluginInterface>("MSALPlugin");
