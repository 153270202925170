import { Component, OnDestroy } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { MenuController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom, Subscription } from "rxjs";
import { Constants } from "src/app/core/constants";
import { Module } from "src/app/models/module";
import { AppService } from "src/app/provider/app.service";
import { GenericService } from "src/app/provider/generic.service";
import { UserService } from "src/app/provider/user.service";
import { PreferenceService } from "src/app/provider/preference.service";
import { CategoryService } from "src/app/provider/categoryservice";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
})
export class MenuComponent implements OnDestroy {
  modules: Module[] = [];

  private loadingEventSubscription: Subscription;
  constructor(private menuCtrl: MenuController, public gs: GenericService, public appService: AppService, public userService: UserService, private translateService: TranslateService, private categoryService: CategoryService, public router: Router) {
    this.loadingEventSubscription = this.userService.getObservable().subscribe((result) => {
      if (result && result.name === "user_menu") {
        this.getData();
      }
    });
  }

  private async getData() {
    if (this.gs.getMenuLoadFlag()) {
      this.gs.publishEvent("activete_loading");
      this.gs.setMenuLoadFlag(false);
      (await this.appService.findMenu()).subscribe({
        next: (data: Module[]) => {
          this.modules = data;
          localStorage.setItem("modules", JSON.stringify(this.modules));
        },
        error: () => {
          this.gs.handleError();
          this.gs.publishEvent("close_loading");
        },
      });
      (await this.appService.getURLPermissionByIdUser()).subscribe({
        next: (data: any) => {
          this.gs.setMenuLoadFlag(true);
          localStorage.setItem("permissions", JSON.stringify(data));
          if (this.gs.getTaskLoadFlag() && this.gs.getCatLoadFlag()) {
            this.categoryService.publishEvent("refresh_time");
            this.gs.publishEvent("close_loading");
            if (!this.gs.getOpenTaskFromPush()) {
              const navigationExtras: NavigationExtras = {
                state: { fromUserCat: true },
              };
              this.router.navigate([Constants.urls.listTasks], navigationExtras);
            }
          }
        },
        error: () => {
          this.gs.setMenuLoadFlag(true);
          this.gs.handleError();
          this.gs.publishEvent("close_loading");
          this.gs.showMessage(this.translateService.instant("comun.errorMenuLoad"));
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.loadingEventSubscription.unsubscribe();
  }

  goToUrl(id, url: string, event) {
    if (url != null && url.length > 0) {
      this.router.navigate([url]);
      this.menuCtrl.toggle();
    } else {
      event.stopPropagation();
    }
  }
}
