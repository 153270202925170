import { Component, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { GenericService } from "src/app/provider/generic.service";

@Component({
  selector: "app-confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.css"],
})
export class ConfirmModalComponent {
  @Input("txt") txt: any = null;
  @Input("title") title: any = null;
  @Input("btn1") btnPrimary: any = null;
  @Input("btn2") btnSecondary: any = this.translateService.instant("comun.cancelar");

  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>, public translateService: TranslateService, public genericService: GenericService) {}

  close(data?: any) {
    this.dialogRef.close(data);
  }
}
