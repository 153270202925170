export class StatsParams {
  userResolution: string;
  userAddr: string;
  javaEnabled: boolean;
  clientTimezoneOffset: number;
  clientDate: Date;
  clientVersion: string;
  urlAccess: string;
  uuid: string;
  appVersion: string;

  constructor(item?: any) {
    this.userResolution =
      item && item.userResolution ? item.userResolution : "";
    this.userAddr = item && item.userAddr ? item.userAddr : "";
    this.javaEnabled = item && item.javaEnabled ? item.javaEnabled : false;
    this.clientTimezoneOffset =
      item && item.clientTimezoneOffset ? item.clientTimezoneOffset : 0;
    this.clientDate = item && item.clientDate ? item.clientDate : new Date();
    this.clientVersion = item && item.clientVersion ? item.clientVersion : "";
    this.urlAccess = item && item.urlAccess ? item.urlAccess : "";
    this.uuid = item && item.uuid ? item.uuid : "";
    this.appVersion = item && item.appVersion ? item.appVersion : "";
  }
}
