import { Component, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-modal-update",
  templateUrl: "./modal-update.component.html",
  styleUrls: ["./modal-update.component.css"],
})
export class ModalUpdateComponent {
  @Input() msj: string;
  loading = false;
  constructor(public dialogRef: MatDialogRef<ModalUpdateComponent>) {}

  close(data?: any) {
    this.dialogRef.close(data);
  }
}
