<ion-grid>
    <ion-row *ngFor="let action of actions" class="ion-align-items-center">
        <ion-col size="2">
            <ion-img *ngIf="action.id == null" src="res/css/images/menu/2-OUTCOME-1.png" class="w-27-h-20">
            </ion-img>
            <ion-img *ngIf="action.id != null"
                src="res/css/images/menu/2-QUICK_OUTCOME-{{action.idDefaultQuickOutcome}}.png"
                class="w-27-h-20"></ion-img>
        </ion-col>
        <ion-col size="10">
            <ion-label>{{action.label}}</ion-label>
        </ion-col>
    </ion-row>
</ion-grid>