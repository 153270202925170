export class ScoredEmailAddress {
  address: string;
  relevanceScore: number;
  selectionLikelihood: string;

  constructor(item?: any) {
    this.address = item && item.address ? item.address : "";
    this.relevanceScore = item && item.relevanceScore ? item.relevanceScore : 0;
    this.selectionLikelihood =
      item && item.selectionLikelihood ? item.selectionLikelihood : "";
  }
}
