import { DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http"; // Import
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from "@azure/msal-angular"; // Import MsalInterceptor
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { BreadcrumbModule } from "xng-breadcrumb";
import { PreferenceService } from "../app/provider/preference.service";
import { UserService } from "../app/provider/user.service";
import { environment } from "../environments/environment";
import { AppRoutingModuleWeb } from "./app-routing.module.web";
import { AppComponentWeb } from "./app.component.web";
import { HomeComponent } from "./components/home/home.component.web";
import { LogoutComponent } from "./components/logout/logout.component.web";
import { SharedModule } from "./components/shared/shared.module";
import { AppService } from "./provider/app.service";
import { GenericService } from "./provider/generic.service";
import { RolesService } from "./provider/roles.service";
import { ApplicationInitializerFactory, HttpLoaderFactory } from "./translation.config";

import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { ErrorHandlerService } from "./core/error.handler.service";
import { MonitoringService } from "./provider/monitoring.service";
import { CategoryMenuComponent } from "./components/category-menu/category-menu.component";
import { MenuComponent } from "./components/menu/menu.component";
import { SafeHtmlPipe } from "./pipes/safeHtml.pipe";
import { CheckScriptsPipe } from "./pipes/checkScripts.pipe";
import { TasksComponent } from "./components/tasks/tasks.component";
import { FilterTasks } from "./pipes/filter-tasks.pipe";
import { DetailsTaskComponent } from "./components/tasks/details-task/details-task.component";
import { PreferenceComponent } from "./components/preference/preference.component";
import { ModalLegendComponent } from "./components/tasks/modal-legend/modal-legend.component";
import { ModalSendMailComponent } from "./components/tasks/modal-send-mail/modal-send-mail.component";
import { ModalColorPickerComponent } from "./components/preference/modal-color-picker/modal-color-picker.component";

import { IntlModule } from "angular-ecmascript-intl";
import { CustomRelativeTimePipe } from "./pipes/customRelativeTime.pipe";
import { ModalUpdateComponent } from "./components/tasks/modal-update/modal-update.component";
export const DATE_FORMAT = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.postLogoutRedirectUri,
      navigateToLoginRequestUrl: environment.navigateToLoginRequestUrl,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read", "people.read"]);
  protectedResourceMap.set("https://graph.windows.net/", ["user.read", "people.read"]);
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me/people", ["user.read", "people.read"]);
  protectedResourceMap.set("/api/", ["api://" + environment.clientId + "/access_as_user"]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
  declarations: [AppComponentWeb, HomeComponent, LogoutComponent, CategoryMenuComponent, MenuComponent, SafeHtmlPipe, CheckScriptsPipe, TasksComponent, FilterTasks, DetailsTaskComponent, PreferenceComponent, ModalLegendComponent, ModalSendMailComponent, ModalColorPickerComponent, CustomRelativeTimePipe, ModalUpdateComponent],
  exports: [SharedModule, FormsModule, AppComponentWeb],
  imports: [
    IonicModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    AppRoutingModuleWeb,
    HttpClientModule,
    MsalModule,
    FontAwesomeModule,
    SharedModule,
    FormsModule,
    MatDialogModule,
    IntlModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector, PreferenceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    GenericService,
    UserService,
    MonitoringService,
    RolesService,
    PreferenceService,
    SplashScreen,
    AppService,
    DatePipe,
    FileOpener,
    File,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponentWeb, MsalRedirectComponent],
})
export class AppModuleWeb {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
