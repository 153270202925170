import { Component, OnDestroy, ViewChild } from "@angular/core";
import { NavigationEnd, NavigationExtras, Router } from "@angular/router";
import { IonInfiniteScroll } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ConfirmModalComponent } from "src/app/components/shared/confirm-modal/confirm-modal.component";
import { Constants } from "src/app/core/constants";
import { GenericPageComponent } from "src/app/core/generic.page.component";
import { TaskGrid } from "src/app/models/grid/task-grid";
import { CategoryService } from "src/app/provider/categoryservice";
import { GenericService } from "src/app/provider/generic.service";
import { TaskService } from "src/app/provider/task.service";
import { ModalSendMailComponent } from "./modal-send-mail/modal-send-mail.component";
import { ModalController } from "@ionic/angular";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { MonitoringService } from "../../provider/monitoring.service";
import { Task } from "../../models/task";

@Component({
  selector: "app-tasks",
  templateUrl: "./tasks.component.html",
})
export class TasksComponent extends GenericPageComponent implements OnDestroy {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  listTasks: TaskGrid[] = [];
  listChecked: TaskGrid[] = [];
  totalTasks = 0;
  title = "All tasks";
  showNoTasks = false;
  isIOS = environment.ios === true;
  private routerEventSubscription: Subscription;
  task: Task;
  locale: any;

  constructor(router: Router, genericService: GenericService, translateService: TranslateService, private categoryService: CategoryService, private taskService: TaskService, public modalController: ModalController, private dialog: MatDialog, private mon: MonitoringService) {
    super(genericService, router, translateService);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.routerEventSubscription = this.router.events.subscribe((event) => {
      const CatMenuFlag = this.router.getCurrentNavigation()?.extras?.state?.fromUserCat;
      if (event instanceof NavigationEnd && event.url == Constants.urls.listTasks && CatMenuFlag) {
        this.listTasks = this.taskService.getTask();
        if (this.listTasks.length == 0 && event.id != 1) {
          this.showNoTasks = true;
        } else {
          this.showNoTasks = false;
          this.locale = this.translateService.currentLang;
        }
        this.title = this.taskService.getNameCat();
      }
    });
  }
  ngOnDestroy(): void {
    this.routerEventSubscription.unsubscribe();
  }

  perfomAction(xpath: string, id: number, externalId: string) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: "400px",
    });
    const instance = dialogRef.componentInstance;
    instance.title = this.translateService.instant("validation.questionconfirm");
    instance.txt = this.translateService.instant("validation.confirmTransaction");
    instance.btnPrimary = this.translateService.instant("comun.confirm");
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.genericService.publishEvent("activete_loading");
        const nId = id;
        const xpathAction = xpath;

        this.getTask(nId);
        (await this.taskService.performActionList(nId, xpathAction, externalId)).subscribe({
          next: () => {
            this.genericService.publishEvent("close_loading");
            this.categoryService.publishEvent("user_cat");
            this.genericService.showMessage(this.translateService.instant("validation.saveTransaction"));
            const task1 = this.task;
            this.mon.logEvent("IWF3 tasks performActionList", {
              userLdap: this.task.userLogin,
              system: this.task.system,
            });
          },
          error: () => {
            this.genericService.handleError();
            this.genericService.showMessage(this.translateService.instant("validation.errorTransaction"));
            this.genericService.publishEvent("close_loading");
            this.mon.logEvent("IWF3 tasks performActionList error", {
              userLdap: this.task.userLogin,
              system: this.task.system,
            });
          },
          complete: () => {
            console.log("taskService perfomAction performActionList complete");
          },
        });
      }
    });
  }

  async perfomActionMultiple(list: any) {
    let cont = 0;
    for (let i = 0; i < list.length; i++) {
      if (i == environment.numMaxMultiApproved) {
        return;
      }
      const nId = list[i].id;
      const xpathAction = list[i].xpath;
      const externalId = list[i].externalId;
      list[i].isChecked = false;

      this.getTask(nId);

      if (xpathAction != "{}" && xpathAction != null) {
        list[i].status = "IN_PROGRESS";

        (await this.taskService.performActionList(nId, xpathAction, externalId)).subscribe({
          error: () => {
            this.genericService.handleError();
            this.genericService.showMessage(this.translateService.instant("validation.errorTransaction"));
            this.mon.logEvent("IWF3 tasks performActionList", {
              userLdap: this.task.userLogin,
              system: this.task.system,
            });
          },
          complete: () => {
            console.log("taskService perfomActionMultiple performActionList complete");
            this.mon.logEvent("IWF3 tasks performActionList", {
              userLdap: this.task.userLogin,
              system: this.task.system,
            });
          },
        });
      } else {
        cont = cont + 1;
        if (cont == environment.numMaxMultiApproved || cont == list.length) {
          return cont;
        }
      }
    }
  }

  public details(id): void {
    const navigationExtras: NavigationExtras = {
      state: { id: id, fromPush: false },
    };
    const task = this.listTasks.find((obj) => obj.id === id);

    if (!task.isRead) {
      const index = this.listTasks.indexOf(task);
      task.isRead = true;
      this.listTasks[index] = task;
      this.taskService.setTaskUnit(task);
      this.taskService.publishEvent("reading_task");
    }

    this.router.navigate([Constants.urls.detailsTask], navigationExtras);
  }

  filterTasksPost = "";

  slice = 20;
  doInfinite(infiniteScroll) {
    setTimeout(() => {
      this.slice += 10;
      infiniteScroll.target.complete();
    }, 300);
  }

  async approve(): Promise<void> {
    const listChecked = this.listTasks.filter((t) => t.isChecked);
    if (listChecked.length >= 1) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: "400px",
      });
      const instance = dialogRef.componentInstance;
      instance.title = this.translateService.instant("validation.questionconfirm");
      instance.txt = this.translateService.instant("validation.textApprove").replace("nTask", listChecked.length).replace("maxTasks", environment.numMaxMultiApproved);
      instance.btnPrimary = this.translateService.instant("comun.confirm");
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          const cont = await this.perfomActionMultiple(listChecked);
          if (cont == environment.numMaxMultiApproved || cont == listChecked.length) {
            return this.genericService.showMessage(this.translateService.instant("validation.noTransaction").replace("maxTasks", environment.numMaxMultiApproved));
          }
          this.genericService.showMessage(this.translateService.instant("validation.saveTransaction"));
        }
      });
    } else {
      this.genericService.showMessage(this.translateService.instant("validation.selectMoreOne"));
    }
  }

  async delete(): Promise<void> {
    const listChecked = this.listTasks.filter((t) => t.isChecked);
    if (listChecked.length >= 1) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: "400px",
      });
      const instance = dialogRef.componentInstance;
      instance.title = this.translateService.instant("validation.questionconfirm");
      instance.txt = this.translateService.instant("validation.taskDelete").replace("nTask", listChecked.length);
      instance.btnPrimary = this.translateService.instant("comun.confirm");
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.genericService.publishEvent("activete_loading");
          const ids = [];
          listChecked.forEach((x) => {
            ids.push(x.id);
          });
          (await this.taskService.deleteTasks(ids)).subscribe({
            next: (data) => {
              this.genericService.showMessage(this.translateService.instant("validation.deleteTasksCorrect").replace("nTask", data));
              this.listChecked = [];
              this.genericService.publishEvent("close_loading");
              this.categoryService.publishEvent("user_cat");
            },
            error: (error) => {
              this.genericService.publishEvent("close_loading");
              this.genericService.showMessage(this.translateService.instant("validation.deleteTasksIncorrect"));
            },
          });
        }
      });
    } else {
      this.genericService.publishEvent("close_loading");
      this.genericService.showMessage(this.translateService.instant("validation.selectMoreOneTask"));
    }
  }

  async showSendMail(id: number) {
    const modal = await this.modalController.create({
      component: ModalSendMailComponent,
      cssClass: "my-custom-class",
      componentProps: {
        id: id,
      },
    });
    return await modal.present();
  }

  doRefresh(event) {
    this.categoryService.publishEvent("user_cat");
    event.target.complete();
  }

  selectAll(event) {
    const elements = document.getElementsByTagName("ion-checkbox");
    if (event.currentTarget.checked) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].checked = true;
      }
    } else {
      for (let i = 0; i < elements.length; i++) {
        elements[i].checked = false;
      }
    }
  }

  async getTask(id: number) {
    this.task = await this.taskService.getById(id);
  }
}
