import { registerPlugin, Plugin } from "@capacitor/core";

export interface BaseOptions {
  clientId: string;
  tenant?: string;
  domainHint?: string;
  authorityType?: "AAD" | "B2C";
  authorityUrl?: string;
  knownAuthorities?: string[];
  keyHash?: string;
  brokerRedirectUriRegistered?: boolean;
}

export interface LoginOptions extends BaseOptions {
  scopes?: string[];
}

export interface authOptions extends BaseOptions {
  scopes?: string[];
  scope?: any;
}

export type LogoutOptions = BaseOptions;

interface MSALPluginInterface extends Plugin {
  loginAnd(options: LoginOptions): Promise<{
    accessToken: string;
    idToken: string;
    scopes: string[];
    username: string;
  }>;
  logoutAnd(options: LogoutOptions): Promise<any>;
  logoutAll(options: LogoutOptions): Promise<void>;
  authorizeAPI: (options: authOptions) => Promise<{
    accessToken: string;
  }>;
}
export const MsAuthPlugin = registerPlugin<MSALPluginInterface>("MsAuthPlugin");
