export const environment = {
  ios: false,
  android: false,
  production: true,
  appMobility20Id: "com.abenewco.simosait.iworkflow3",
  appMobility20Ver: "1.0.1",
  url_gateway: "https://apps-iwf-back-abg-weu.azurewebsites.net",
  //Auth msal
  clientId: "2f149302-e15d-46b8-82aa-0af94b837b29",
  authority: "https://login.microsoftonline.com/2d46fb13-81c6-467f-987e-26bcc78a8706",
  scopeAPI: "api://2f149302-e15d-46b8-82aa-0af94b837b29/access_as_user",
  scopeProfileGraph: "user.read",
  scopePeopleGraph: "people.read",
  redirectUri: "https://iwf.coxabengoa.com",
  postLogoutRedirectUri: "https://iwf.coxabengoa.com/logout",
  navigateToLoginRequestUrl: true,
  numMaxMultiApproved: 10,
  appInsights: {
    instrumentationKey: "d2d2d3ad-1ca8-4ddb-aa2f-f8b7b011d311",
  },
};
