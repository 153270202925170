import { ErrorHandler, Injectable } from "@angular/core";
import { MonitoringService } from "../provider/monitoring.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private mon: MonitoringService) {
    super();
  }

  handleError(error: Error) {
    console.log(error); // Manually log exception
    this.mon.logException(error);
  }
}
