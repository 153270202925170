<div class="container">
  <h1 mat-dialog-title *ngIf="!title">¡Vas a eliminar el elemento!</h1>
  <h1 mat-dialog-title *ngIf="title" [innerHTML]="title"></h1>

  <div mat-dialog-content>
    <label *ngIf="!txt">¿Estás seguro?</label>
    <p *ngIf="txt" [innerHTML]="txt"></p>
  </div>

  <div class="footer" mat-dialog-footer>
    <ion-button
      color="primary"
      *ngIf="btnPrimary"
      (click)="close({ close: true })"
    >
      {{ btnPrimary }}
    </ion-button>
    <ion-button color="medium" (click)="close()"
      >{{ btnSecondary }}
    </ion-button>
  </div>
</div>
