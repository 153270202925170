<div class="container app-loader" *ngIf="loading">
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
</div>
<ion-header class="ion-no-border">
  <ion-toolbar style="height: 30px"> </ion-toolbar>
</ion-header>
<ion-content [scrollEvents]="true">
  <form [formGroup]="mailForm">
    <ion-grid>
      <ion-row>
        <ion-col size-lg="2" size-sm="12" size-xs="12" size="2">
          <ion-label>{{ "mail.user" | translate }}</ion-label>
        </ion-col>
        <ion-col size-lg="10" size-sm="12" size-xs="12" size="10">
          <input [formControl]="contactCtrl" class="searchGlobal full-width o-l-n userMail"
            placeholder="{{ 'mail.new_search' | translate }}" [matAutocomplete]="auto"
            (keyup.Enter)="addCustom($event.target.value)" (keyup)="onKey($event.target.value)"
            style="font-size: 1rem" />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let contact of filteredContacts" [value]="contact" style="font-size: 1rem">
              <div class="multiline-auto-complete-options">
                <div style="color: #03618e">{{ contact.displayName }}<br /></div>
                <div style="font-size: 1rem">
                  {{ contact.scoredEmailAddresses[0].address }}
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-lg="3" size-sm="12" size-xs="12" size="3">
          <ion-label>{{ "mail.recipients" | translate }}</ion-label>
        </ion-col>
        <ion-col size-lg="9" size-sm="12" size-xs="12" size="9">
          <ion-chip *ngFor="let e of contacts; let i = index">
            <ion-label color="secundary">{{ e }}</ion-label>
            <ion-icon name="close-outline" (click)="removeItem(i)"></ion-icon>
          </ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <p>{{ "mail.usersNote1" | translate }}</p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-lg="2" size-sm="12" size-xs="12" size="2">
          <ion-label>
            <p>{{ "mail.subject" | translate }}</p>
          </ion-label>
        </ion-col>
        <ion-col size-lg="10" size-sm="12" size-xs="12" size="10">
          <ion-input type="text" formControlName="subject" style="border: solid 1px"></ion-input>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-lg="2" size-sm="12" size-xs="12" size="2">
          <ion-label>
            <p>{{ "mail.comment" | translate }}</p>
          </ion-label>
        </ion-col>
        <ion-col size-lg="10" size-sm="12" size-xs="12" size="10">
          <ion-textarea formControlName="comments" style="border: solid 1px" rows="6" cols="20"> </ion-textarea>
          <ion-row>
            <ion-col size-lg="4" size-sm="4" size-xs="4" size="4">
              <ion-label>
                <p>{{ "mail.attachments" | translate }}</p>
              </ion-label>
            </ion-col>
            <ion-col size-lg="2" size-sm="2" size-xs="2" size="2">
              <ion-checkbox color="secondary" formControlName="anexos"></ion-checkbox>
            </ion-col>
          </ion-row>
          <ion-row align-items-center>
            <ion-col col-2>
              <ion-button type="submit" (click)="sendMail()">
                {{ "mail.send" | translate }}
              </ion-button>
            </ion-col>
            <ion-col col-2>
              <ion-button (click)="close()">
                {{ "mail.cancel" | translate }}
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
  <app-details-task *ngIf="id !== 0" #taskChild [id]="id" [hidden]="true"></app-details-task>
</ion-content>