<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
    </ion-row>
    <ion-row>
      <ion-col>

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>