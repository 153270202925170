import { Component, Input } from '@angular/core';
import { OutCome } from 'src/app/models/outCome';



@Component({
  selector: "app-modal-legend",
  templateUrl: "./modal-legend.component.html",
})
export class ModalLegendComponent {
  @Input('actions') actions: OutCome[] = [];
  
}