import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { TaskService } from "./task.service";
declare var document: any;

@Injectable({ providedIn: "root" })
export class ScriptService {
  private scripts: any = {};

  constructor(private taskService: TaskService, private genericService: GenericService) {}

  async load(scriptsSrc: HTMLScriptElement[]) {
    var promises: any[] = [];
    const jsQuery = "/res/js/jquery.js";
    this.scripts[jsQuery] = { loaded: false };
    promises.push(await this.loadSrcScript(jsQuery));
    scriptsSrc.forEach((s: any) => {
      let jsPath = s.src.substring(s.src.toLowerCase().indexOf("/res"));
      this.scripts[jsPath] = { loaded: false };
      promises.push(this.loadSrcScript(jsPath));
    });
    return Promise.all(promises);
  }

  loadSrcScript(sElemURL: string) {
    return new Promise((resolve, reject) => {
      if (this.scripts[sElemURL].loaded) {
        resolve({ script: sElemURL, loaded: true, status: "Already Loaded" });
      } else {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = sElemURL;
        script.async = true;
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[sElemURL].loaded = true;
              resolve({ script: sElemURL, loaded: true, status: "Loaded" });
            }
          };
        } else {
          script.onload = () => {
            this.scripts[sElemURL].loaded = true;
            resolve({ script: sElemURL, loaded: true, status: "Loaded" });
          };
        }
        script.onerror = (error: any) => resolve({ script: sElemURL, loaded: false, status: "Loaded" });

        if (!this.isLoadedScript(sElemURL)) {
          document.getElementsByTagName("head")[0].appendChild(script);
        } else {
          resolve({ script: sElemURL, loaded: true, status: "Loaded" });
        }
      }
    });
  }
  isLoadedScript(lib) {
    return document.querySelectorAll('[src="' + lib + '"]').length > 0;
  }
  loadNotSrcScript(status: string) {
    const docDetails = document.getElementById("detailsTask");
    const scripts = <HTMLScriptElement[]>docDetails.getElementsByTagName("script");
    const scriptsNotSrc = Array.from(scripts).filter((s) => !s.src);
    scriptsNotSrc.forEach(async (scriptNotSrc) => {
      const newScript = document.createElement("script");
      Array.from(scriptNotSrc.attributes).forEach((attr) => newScript.setAttribute(attr.name, attr.value));
      newScript.appendChild(document.createTextNode(scriptNotSrc.innerHTML));
      scriptNotSrc.parentNode.replaceChild(newScript, scriptNotSrc);
    });
    if (this.taskService.isDisable(status)) {
      this.taskService.disableTask();
    }
    this.genericService.publishEvent("close_loading");
  }
}
