import { Component, Input, OnInit, NgZone, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/core/constants";
import { GenericPageComponent } from "src/app/core/generic.page.component";
import { OutCome } from "src/app/models/outCome";
import { GenericService } from "src/app/provider/generic.service";
import { TaskService } from "src/app/provider/task.service";
import { ModalLegendComponent } from "../modal-legend/modal-legend.component";
import { ModalSendMailComponent } from "../modal-send-mail/modal-send-mail.component";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { environment } from "src/environments/environment";
import { TParam } from "src/app/models/tParam";
import { CategoryService } from "src/app/provider/categoryservice";
import jsPDF from "jspdf";
import { MatDialog } from "@angular/material/dialog";
import { ModalController } from "@ionic/angular";
import { ConfirmModalComponent } from "src/app/components/shared/confirm-modal/confirm-modal.component";
import { AlertController } from "@ionic/angular";
import { MonitoringService } from "../../../provider/monitoring.service";

import { Task } from "../../../models/task";

declare function focus(xpathFocus: string): any;
declare function selectAction(xpath: string, value: string): any;
declare function arraysNew(): any;
@Component({
  selector: "app-details-task",
  templateUrl: "./details-task.component.html",
})
export class DetailsTaskComponent extends GenericPageComponent implements OnInit {
  taskRender: any = "";
  status = "";
  externalId = "";
  code = 0;
  actions: OutCome[] = [];
  nameTask = "";
  disableFooter = true;
  fromPush = false;
  fromMail = false;
  @Input("id") public id = 0;

  task: Task;

  constructor(router: Router, genericService: GenericService, translateService: TranslateService, private taskService: TaskService, private categoryService: CategoryService, private fileOpener: FileOpener, private file: File, public modalController: ModalController, private route: ActivatedRoute, private dialog: MatDialog, private zone: NgZone, private alertController: AlertController, private mon: MonitoringService) {
    super(genericService, router, translateService);
  }

  async ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if (this.id) {
      this.fromMail = true;
      this.getData(this.id, false);
    }
    this.route.params.subscribe(() => {
      const ids = this.router.getCurrentNavigation()?.extras?.state?.id;
      this.fromPush = this.router.getCurrentNavigation()?.extras?.state?.fromPush;
      this.getData(ids, this.fromPush);
    });
  }

  async getTask(id: number) {
    if (id != null && id) {
      this.task = await this.taskService.getById(id);
    }
  }

  private getData(id: number, fromPush: boolean) {
    if (id != null && id) {
      this.zone.run(() => {
        this.genericService.publishEvent("activete_loading");
        this.id = id;
        this.getDataHT(this.id, fromPush);
      });
    }
  }

  async innerHTMLClick(event) {
    if (event.target && event.target instanceof HTMLAnchorElement && event.target.href && event.target.href.includes("downloadFile")) {
      this.genericService.publishEvent("activete_loading");
      event.preventDefault();
      event.stopPropagation();

      (await this.taskService.getDocument(event.target.href)).subscribe({
        next: (data) => {
          if (data.body.size == 0) {
            this.genericService.showMessage(this.translateService.instant("task.downloadDocumentError"));
            this.genericService.publishEvent("close_loading");
          } else {
            const name = decodeURIComponent(event.target.href.substr(event.target.href.lastIndexOf("name=") + 5));
            const newBlob = new Blob([data.body], { type: data.body.type });
            if (environment.ios || environment.android) {
              this.openIosBlob(newBlob, name);
            } else {
              const url = window.URL.createObjectURL(newBlob);
              const anchor = document.createElement("a");
              anchor.download = name;
              anchor.href = url;
              anchor.click();
              this.genericService.publishEvent("close_loading");
            }
          }
        },
        error: () => {
          this.genericService.publishEvent("close_loading");
          this.genericService.handleError();
          this.genericService.showMessage(this.translateService.instant("task.downloadDocumentError"));
        },
        complete: () => {
          console.log("taskService getDocument complete");
        },
      });
    }
  }

  async perfomAction(xpathFocus: string, prms: TParam[], id: number, externalId: string, code: number) {
    const nId = this.id;

    this.getTask(this.id);

    for (let i = 0; i < prms.length; i++) {
      selectAction(prms[i].xpath, prms[i].value);
      focus(xpathFocus);
    }
    const param = this.taskService.generateParams(xpathFocus, prms, id);
    for (let i = 0; i < param.length; i++) {
      if (param[i].xpath === "Error" && param[i].value !== "") {
        return this.genericService.showMessage(this.translateService.instant("validation.requiredField").replace("label", param[i].value));
      } else if (param[i].value === "") {
        return this.genericService.showMessage(this.translateService.instant("validation.requiredAction"));
      }
    }
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: "400px",
    });
    const instance = dialogRef.componentInstance;
    instance.title = this.translateService.instant("validation.questionconfirm");
    instance.txt = this.translateService.instant("validation.confirmTransaction");
    instance.btnPrimary = this.translateService.instant("comun.confirm");
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.genericService.publishEvent("activete_loading");
        (await this.taskService.performActionDetail(nId, param, externalId, code)).subscribe({
          next: () => {
            this.disableFooter = true;
            this.genericService.publishEvent("close_loading");
            this.categoryService.publishEvent("user_cat");
            this.genericService.showMessage(this.translateService.instant("validation.saveTransaction"));
            this.mon.logEvent("IWF3 details-task performActionDetail", {
              userLdap: this.task.userLogin,
              system: this.task.system,
            });
          },
          error: () => {
            this.genericService.publishEvent("close_loading");
            this.genericService.handleError();
            this.genericService.showMessage(this.translateService.instant("validation.errorTransaction"));
            this.mon.logEvent("IWF3 details-task performActionDetail", {
              userLdap: this.task.userLogin,
              system: this.task.system,
            });
          },
          complete: () => {
            console.log("taskService performActionDetail complete");
          },
        });
      }
    });
  }

  async getDataHT(idTask: number, fromPush: boolean) {
    await arraysNew();
    this.taskRender = "";
    this.status = "";
    this.externalId = "";
    this.code = 0;
    this.actions = [];
    this.nameTask = "";
    const headers = await this.genericService.getHeaders();
    this.taskService.getRenderTask(headers, idTask).subscribe({
      next: (dataTask) => {
        if (dataTask) {
          this.status = dataTask.status;
          this.taskRender = dataTask.content;
          this.externalId = dataTask.externalId;
          this.nameTask = dataTask.nameTask;
          this.code = dataTask.code;
          this.actions = dataTask.outcomes;
          const msjTareaNoComp = dataTask.content.includes("mensajeTareaNoCompatible");
          if (this.fromMail) {
            this.disableFooter = true;
            this.fromMail = false;
          } else {
            if (msjTareaNoComp || dataTask.status == "COMPLETED" || dataTask.status == "IN_PROGRESS" || dataTask.status == "OBSOLETE") {
              this.disableFooter = true;
            } else {
              this.disableFooter = false;
            }
          }
          if (fromPush) {
            if (this.genericService.getCatLoadFlag() && this.genericService.getTaskLoadFlag()) {
              this.categoryService.publishEvent("user_cat_back");
            }
            setTimeout(() => {
              this.genericService.setOpenTaskFromPush(false);
            }, 5000);
          }
        } else {
          this.genericService.showMessage(this.translateService.instant("validation.errorLoadTask"));
          console.log("Task not found.");
        }
      },
      error: (err) => {
        this.genericService.setOpenTaskFromPush(false);
        this.genericService.showMessage(this.translateService.instant("validation.errorLoadTask"));
        console.log("Task back error.");
        console.log(JSON.stringify(err));
      },
    });
  }

  showLegend() {
    const dialogRef = this.dialog.open(ModalLegendComponent, {
      width: "600px",
    });
    const instance = dialogRef.componentInstance;
    instance.actions = this.actions;
  }

  async showSendMail() {
    const modal = await this.modalController.create({
      component: ModalSendMailComponent,
      componentProps: {
        id: 0,
      },
    });
    return await modal.present();
  }

  async downloadPDF() {
    this.genericService.publishEvent("activete_loading");
    const docum = document.getElementById("detailsTask");
    let doc = new jsPDF("p", "pt", "a4");
    const margin = 20;
    const scale = (doc.internal.pageSize.width - margin * 2) / docum.scrollWidth;
    const styleDoc = document.body.getAttribute("style");
    document.body.setAttribute("style", "-webkit-text-size-adjust: 100%;");

    const ignoreEL = function (el) {
      if ("link" == el.tagName || "script" == el.tagName || "mensajeTareaFallida" == el.id || el.classList.contains("tab-box") || el.classList.contains("tareaFallidaDiv")) {
        return true;
      }
    };
    const onclone = function (clonedDoc: Document) {
      /* TODO Bug 266 Descarga de PDF se pierden los labels en Ios
      Pendiente de solución definitiva modificando la transformada
      const labels = clonedDoc.getElementsByTagName("label");
      let k = labels.length;
      for (let l = 0; l < labels.length || k > 0; l) {
        let spanEL = document.createElement("span");
        spanEL.setAttribute("class", "label");
        spanEL.innerHTML = labels[l].innerHTML;
        labels[l].replaceWith(spanEL);
        k--;
      }
      */
      const contentTabs = clonedDoc.getElementsByClassName("tabcontent");
      for (let ct = 0; ct < contentTabs.length; ct++) {
        contentTabs[ct].classList.remove("hide");
      }
      const contentOdd = clonedDoc.querySelectorAll(".odd");
      for (let co = 0; co < contentOdd.length; co++) {
        contentOdd[co].classList.remove("odd");
        contentOdd[co].classList.add("odd-print");
      }
    };
    await doc
      .html(docum, {
        margin: [50, 40, 50, 40],
        html2canvas: {
          ignoreElements: ignoreEL,
          scale: scale,
          onclone: onclone,
        },
      })
      .then(() => this.open(doc, styleDoc))
      .catch((errorOpener) => {
        this.genericService.publishEvent("close_loading");
        this.genericService.showMessage("Error PDF " + errorOpener);
      });
  }
  private open(doc: jsPDF, styleDoc: string) {
    document.body.setAttribute("style", styleDoc);
    if (environment.ios || environment.android) {
      const pdfOutput = doc.output("blob");
      this.openIosBlob(pdfOutput, this.nameTask);
    } else {
      doc.save(this.nameTask + ".pdf");
      this.genericService.publishEvent("close_loading");
    }
  }
  private openIosBlob(pdfOutput: Blob, name: string) {
    let dir = null;
    if (environment.ios) {
      dir = this.file.syncedDataDirectory;
    } else {
      dir = this.file.dataDirectory;
    }
    this.file.writeFile(dir, name, pdfOutput, { replace: true }).then(
      (success) => {
        this.genericService.publishEvent("close_loading");
        this.fileOpener.open(dir + name, pdfOutput.type).catch((errorOpener) => {
          this.genericService.showMessage("Error opening pdf file " + errorOpener);
        });
      },
      (err) => {
        this.genericService.publishEvent("close_loading");
        this.genericService.showMessage(" writing File error : " + err);
      }
    );
  }

  returnTaskList() {
    const navigationExtras: NavigationExtras = {
      state: { fromUserCat: true },
    };
    this.router.navigate([Constants.urls.listTasks], navigationExtras);
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class m-5",
      buttons: [
        {
          text: this.translateService.instant("task.legend"),
          cssClass: "buttons-alert-info m-r-10 m-l-10 m-t-10 f-w-b",
          handler: () => {
            this.showLegend();
          },
        },
        {
          text: this.translateService.instant("comun.sendTask"),
          cssClass: "buttons-alert-info m-r-10 m-l-10 f-w-b",
          handler: () => {
            this.showSendMail();
          },
        },
        {
          text: this.translateService.instant("comun.downloadPDF"),
          cssClass: "buttons-alert-info m-r-10 m-l-10 f-w-b",
          handler: () => {
            this.downloadPDF();
          },
        },
      ],
    });

    await alert.present();
  }
}
