import { ScoredEmailAddress } from "./scoredEmailAddress";

export class People {
  id: string;
  displayName: string;
  scoredEmailAddresses: ScoredEmailAddress[] = [];

  constructor(item?: any) {
    this.id = item && item.id ? item.id : "";
    this.displayName = item && item.displayName ? item.displayName : "";
    this.scoredEmailAddresses =
      item &&
      item.scoredEmailAddresses &&
      Array.isArray(item.scoredEmailAddresses)
        ? item.scoredEmailAddresses.map((x) => new ScoredEmailAddress(x))
        : [];
  }
}
