<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="returnTaskList()">
        <fa-icon icon="chevron-left" class="p-0 fa-icon-list icon-align-justify"></fa-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="presentAlert()" color="primary">
        <fa-icon icon="info-circle" class="p-0 m-0 fa-icon-list icon-align-justify"></fa-icon>
      </ion-button>
    </ion-buttons>
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col size="12">
          <ion-title class="p-0">{{ nameTask }}</ion-title>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col (click)="innerHTMLClick($event)">
        <div id="detailsTask" [innerHTML]="taskRender | checkScripts: status | safeHtml"></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer *ngIf="!disableFooter">
  <ion-toolbar>
    <ion-tabs>
      <ion-tab-bar slot="bottom" class="j-c-s-d-b"
        style="width: 100%; overflow-x: auto; overflow-y: hidden; display: block;">
        <ion-row class="ion-align-items-start" style="flex-wrap: nowrap;">
          <ion-col class="p-0 m-t-4" *ngFor="let action of actions">
            <ion-tab-button
              (click)="perfomAction(action.xpathFocus, action.prms, action.idDefaultQuickOutcome, externalId, code)">
              <ion-img *ngIf="action.id == null" src="res/css/images/menu/2-OUTCOME-1.png" class="w-27-h-20"></ion-img>
              <ion-img *ngIf="action.id != null"
                src="res/css/images/menu/2-QUICK_OUTCOME-{{action.idDefaultQuickOutcome}}.png" class="w-27-h-20">
              </ion-img>
              <ion-label style="font-size: calc(5px + 0.390625vw); text-overflow: ellipsis;"
                class="l-d-n">{{action.label}}</ion-label>
            </ion-tab-button>
          </ion-col>
        </ion-row>
      </ion-tab-bar>
    </ion-tabs>
  </ion-toolbar>
</ion-footer>