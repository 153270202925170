import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class CategoryService {
  private customSubject = new Subject<any>();
  constructor() {}

  getObservable(): Subject<any> {
    return this.customSubject;
  }
  publishEvent(event: string) {
    this.publishCustomEvent({
      name: event,
    });
  }

  publishCustomEvent(data: any) {
    this.customSubject.next(data);
  }
}
