<ion-app>
  <div class="container app-loader" *ngIf="loading">
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  </div>

  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" width="100">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title> </ion-title>
          <ion-grid>
            <ion-row class="ion-align-items-center">
              <ion-col size="auto">
                <div class="login-form-flex">
                  <ion-img class="logo-img" alt="iWorkflow" src="./assets/logos/logo.png"></ion-img>
                </div>
              </ion-col>
              <ion-col size="auto">
                <h5>iWorkflow 3</h5>
                <p class="m-t-6">
                  {{ "comun.ver" | translate }}{{ version }}
                </p>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-toolbar>
      </ion-header>
      <ion-content color="light">
        <ion-grid class="p-0-p-l-10">
          <ion-row align-items-center>
            <ion-col class="p-0" size="12">
              <app-category-menu></app-category-menu>
              <app-menu></app-menu>
              <ion-menu-toggle auto-hide="false">
                <ion-item detail="false" color="light" [routerDirection]="'root'" [routerLink]="'/'" (click)="logout()"
                  lines="full" class="b-s-n">
                  <ion-icon class="m-r-10" slot="start" [name]="'log-out'"></ion-icon>
                  <ion-label>{{ "menu.logout" | translate }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
      <ion-footer class="ion-no-border">
        <ion-toolbar color="light">
          <ion-grid class="p-0-p-l-10">
            <ion-row align-items-center>
              <ion-col class="p-0" size="12">
                <ion-item color="light" class="b-s-n">
                  <fa-icon icon="sync" class="ion-icon-task m-r-10" (click)="synchronize()"></fa-icon>
                  <ion-label class="ion-text-wrap  ion-text-center">
                    <p *ngIf="refreshFlag">
                      <span *ngIf="refreshTime">{{'comun.lastSync' | translate}}: {{refreshTime | date:'dd/MM/yyyy
                        HH:mm'}}</span>
                      <span *ngIf="!refreshTime">{{'comun.sync' | translate}}</span>
                    </p>
                    <p *ngIf="!refreshFlag">
                      <ion-icon name="warning-outline" class="f-s-18 v-a-s"></ion-icon>
                      {{ "comun.syncError" | translate }}
                    </p>
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>