import { TranslateModule } from "@ngx-translate/core";
import { GenericPageComponent } from "../../core/generic.page.component";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";

import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatIconModule } from "@angular/material/icon";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DateAdapter, MatNativeDateModule } from "@angular/material/core";
import { MyDateAdapter } from "src/app/pipes/my.date.adapter";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from "@angular/material/select";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatRadioModule } from "@angular/material/radio";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatChipsModule } from "@angular/material/chips";

import { TableModule } from "primeng/table";
import { DropdownModule } from "primeng/dropdown";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ToastModule } from "primeng/toast";
import { InputTextModule } from "primeng/inputtext";
import { AutoCompleteModule } from "primeng/autocomplete";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CalendarModule } from "primeng/calendar";
import { MultiSelectModule } from "primeng/multiselect";

import { BreadcrumbModule } from "xng-breadcrumb";
import { IonicModule } from "@ionic/angular";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { UppercaseInputDirective } from "src/app/pipes/uppercase-input.directive";

const components = [ConfirmModalComponent, GenericPageComponent];

const pipes = [UppercaseInputDirective];

const materialComponent = [MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatRadioModule, MatTabsModule, MatCheckboxModule, MatTableModule, MatButtonModule, MatSelectModule, MatPaginatorModule, MatListModule, MatCardModule, MatTooltipModule, MatIconModule, MatAutocompleteModule, MatSlideToggleModule, MatSnackBarModule, MatChipsModule, MatMomentDateModule];
const primeComponent = [DragDropModule, TableModule, DropdownModule, ConfirmDialogModule, InputTextModule, AutoCompleteModule, ToastModule, CalendarModule, MultiSelectModule];
@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, IonicModule, ReactiveFormsModule, FontAwesomeModule, BreadcrumbModule, materialComponent, primeComponent, TranslateModule.forChild()],
  declarations: [components, pipes],
  exports: [components, pipes, materialComponent, primeComponent, IonicModule, TranslateModule],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class SharedModule {}
