import { HttpClient } from "@angular/common/http";
import { Injectable, Input } from "@angular/core";
import { StatsParams } from "../models/statsParams";
import { GenericService } from "./generic.service";
import { Observable, firstValueFrom } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Device } from "@capacitor/device";
import { MonitoringService } from "./monitoring.service";
import { environment } from "src/environments/environment";
import { PreferenceService } from "./preference.service";
import { UserService } from "./user.service";
import { CategoryService } from "./categoryservice";
import { Preference } from "../models/preference";
import { TranslateService } from "@ngx-translate/core";
import { ModalController } from "@ionic/angular";
import { ModalUpdateComponent } from "../components/tasks/modal-update/modal-update.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  msj = "";
  constructor(private http: HttpClient, private genericService: GenericService, private mon: MonitoringService, private preferenceService: PreferenceService, private userService: UserService, private categoryService: CategoryService, private translateService: TranslateService, public modalController: ModalController, private dialog: MatDialog) {}

  public async saveStats(item: StatsParams): Promise<Observable<string>> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/utils/statUser";
    return this.http
      .post(URL, item, {
        headers: headers,
        responseType: "text",
      })
      .pipe(
        map((data: string) => {
          return data;
        })
      );
  }

  async writeStatsUser(version: string) {
    this.mon.logEvent("IWF3 writeStatsUser");
    const item: StatsParams | null = await this.getStatsParams(version);
    if (item) {
      (await this.saveStats(item)).subscribe({
        next: () => {
          this.mon.logEvent("IWF3 writeStatsUser saveStats data");
        },
        error: (error) => {
          this.mon.logEvent("IWF3 writeStatsUser saveStats error", error);
          this.mon.logException(error);
          this.genericService.handleError();
        },
      });
    }
  }

  private async getStatsParams(version: string) {
    try {
      const item: StatsParams = new StatsParams();
      item.userResolution = window.innerWidth + "x" + window.innerHeight;
      item.userAddr = window.location.hostname;
      item.javaEnabled = false;
      item.clientDate = new Date();
      item.clientTimezoneOffset = item.clientDate.getTimezoneOffset() / -60;
      item.clientVersion = (await Device.getInfo()).webViewVersion;
      item.urlAccess = window.location.href;
      if (environment.ios) {
        item.uuid = (await Device.getId()).identifier;
      }
      item.appVersion = version;
      this.mon.logEvent("IWF3 getStatsParams ", item);
      return item;
    } catch (error: any) {
      this.mon.logEvent("IWF3 getStatsParams error", error);
      this.mon.logException(error);
      return null;
    }
  }

  async findPref() {
    await this.preferenceService
      .findPreferenceByUser(true)
      .then((data) => {
        const item = new Preference(data);
        this.changeFontSize(item.fontSize);
        this.translateService.use(item.language);
      })
      .catch((error) => {
        console.error(error);
        this.translateService.use("es");
      });
  }

  async publishUserMenuUserCat() {
    this.userService.publishEvent("user_menu");
    this.categoryService.publishEvent("user_cat");
  }

  changeFontSize(newFontSize: number) {
    const root = document.documentElement;

    switch (newFontSize) {
      case 1:
        root.style.setProperty("font-size", "12px");
        break;
      case 2:
        root.style.setProperty("font-size", "14px");
        break;
      case 3:
        root.style.setProperty("font-size", "16px");
    }

    const mffElements = Array.from(document.getElementsByClassName("mat-form-field") as HTMLCollectionOf<HTMLElement>);

    this.setStyleHTMLElements(mffElements, newFontSize);
  }

  setStyleHTMLElements(array: HTMLElement[], newFontSize: number) {
    for (const elem of array) {
      switch (newFontSize) {
        case 1:
          elem.style.setProperty("font-size", "12px");
          break;
        case 2:
          elem.style.setProperty("font-size", "14px");
          break;
        case 3:
          elem.style.setProperty("font-size", "16px");
      }
    }
  }

  public async getMsjVersion(version: string) {
    const res = await this.getMsjAboutVersion(version);
    if (res === "BLOCKING" || res === "MINOR") {
      const dialogRef = this.dialog.open(ModalUpdateComponent, {
        width: "600px",
        disableClose: true,
      });
      const instance = dialogRef.componentInstance;
      instance.msj = res;
    }
    if (res === "BLOCKING") {
      return true;
    }
    return false;
  }

  public async getMsjAboutVersion(version: string) {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/utils/msjAboutVersion";
    return firstValueFrom(
      this.http
        .post(URL, version, {
          headers: headers,
          responseType: "text",
        })
        .pipe((data: any) => {
          return data;
        }, catchError(this.genericService.handleError()))
    );
  }
}
