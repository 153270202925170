<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>{{ "preference.title" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form id="preferenceForm">
    <ion-grid wrap>
      <ion-row>
        <ion-col size-lg="12" size-sm="12" size-xs="12" size="12" class="mat-elevation-z8">
          <ion-button (click)="save()" type="submit">{{ "comun.guardar" | translate }}</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid wrap>
      <ion-row>
        <ion-col size-lg="12" size-sm="12" size-xs="12" size="12" class="mat-elevation-z8">
          <ion-card>
            <ion-card-header>
              <ion-card-title class="f-s-18">{{ "preference.title" | translate }}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-grid>
                <ion-row>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <mat-form-field class="component-width">
                      <ion-label>{{ "preference.language" | translate }} *</ion-label>
                      <mat-select [(value)]="lang" (selectionChange)="onChange($event.value)" required>
                        <mat-option *ngFor="let language of languages" [value]="language.value"> {{ language.label | translate }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <ion-label> * {{ "preference.languageInfo" | translate }}</ion-label>
                  </ion-col>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <mat-form-field class="component-width">
                      <ion-label>{{ "preference.completedTasksDwellTime" | translate }} </ion-label>
                      <mat-select [(value)]="sel1" (selectionChange)="onChangeNum(1, $event.value)" required>
                        <mat-option *ngFor="let completedTasksDwellTime of completedTasksDwellTimes" [value]="completedTasksDwellTime.value"> {{ completedTasksDwellTime.label | translate }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <mat-form-field class="component-width">
                      <ion-label>{{ "preference.failedTasksDwellTime" | translate }}</ion-label>
                      <mat-select [(value)]="sel2" (selectionChange)="onChangeNum(2, $event.value)" required>
                        <mat-option *ngFor="let failedTasksDwellTime of failedTasksDwellTimes" [value]="failedTasksDwellTime.value"> {{ failedTasksDwellTime.label | translate }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ion-col>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <mat-form-field class="component-width">
                      <ion-label>{{ "preference.automaticRefresh" | translate }} </ion-label>
                      <mat-select [(value)]="sel3" (selectionChange)="onChangeNum(3, $event.value)" required>
                        <mat-option *ngFor="let automaticRefresh of automaticsRefresh" [value]="automaticRefresh.value"> {{ automaticRefresh.label | translate }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ion-col>
                  <ion-col [ngClass]="!isIOS ? 'noShowFontSize' : ''" size-lg="4" size-sm="12" size-xs="12" size="4">
                    <mat-form-field class="component-width">
                      <ion-label>{{ "preference.size" | translate }} </ion-label>
                      <mat-select [(value)]="sel4" (selectionChange)="onChangeNum(4, $event.value)">
                        <mat-option *ngFor="let fontSize of fontSizes" [value]="fontSize.value"> {{ fontSize.label | translate }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-title class="f-s-18">{{ "preference.processes" | translate }}</ion-card-title>
            </ion-card-header>
            <ion-card-content class="p-0">
              <div *ngFor="let c0 of catMenu">
                <ion-item detail="false" color="light" (click)="c0.open = !c0.open" button="true" class="b-s-n i-c-b-n">
                  <ion-label>
                    <p class="menulist c-n" *ngIf="c0.name === ''">-</p>
                    <p class="menulist c-n" *ngIf="c0.name !== ''">{{ c0.name }}</p>
                  </ion-label>
                  <span class="p-r-10" style="position: relative">
                    <fa-icon [style.color]="c0.color === null ? '#e0e0e0' : c0.color" (click)="showColorPicker(c0.categoryId, '', '', '', c0.procSystem)" icon="square" class="f-s-30"></fa-icon>
                  </span>
                  <div class="min-w-20">
                    <div *ngIf="c0.catMenu !== null && c0.catMenu.length > 0">
                      <ion-icon slot="end" name="chevron-down" *ngIf="!c0.open"></ion-icon>
                      <ion-icon slot="end" name="chevron-up" *ngIf="c0.open"></ion-icon>
                    </div>
                  </div>
                </ion-item>
                <div *ngIf="c0.open && c0.catMenu !== null && c0.catMenu.length > 0">
                  <div *ngFor="let c1 of c0.catMenu">
                    <ion-item detail="false" color="light" (click)="c1.open = !c1.open" button="true" class="b-s-n i-c-b-n">
                      <ion-label>
                        <p class="menulist p-0-p-l-10 c-n" *ngIf="c1.name === ''">-</p>
                        <p class="menulist p-0-p-l-10 c-n" *ngIf="c1.name !== ''">
                          {{ c1.name }}
                        </p>
                      </ion-label>
                      <span class="p-r-10" style="position: relative">
                        <fa-icon [style.color]="c1.color === null ? '#e0e0e0' : c1.color" (click)="showColorPicker(c0.categoryId, c1.categoryId, '', '', c1.procSystem)" icon="square" class="f-s-30"></fa-icon>
                      </span>
                      <div class="min-w-20">
                        <div *ngIf="c1.catMenu !== null && c1.catMenu.length > 0">
                          <ion-icon slot="end" name="chevron-down" *ngIf="!c1.open"></ion-icon>
                          <ion-icon slot="end" name="chevron-up" *ngIf="c1.open"></ion-icon>
                        </div>
                      </div>
                    </ion-item>
                    <div *ngIf="c1.open && c1.catMenu !== null && c1.catMenu.length > 0">
                      <div *ngFor="let c2 of c1.catMenu">
                        <ion-item detail="false" color="light" (click)="c2.open = !c2.open" button="true" class="b-s-n i-c-b-n">
                          <ion-label>
                            <p class="menulist p-l-20 c-n" *ngIf="c2.name === ''">-</p>
                            <p class="menulist p-l-20 c-n" *ngIf="c2.name !== ''">
                              {{ c2.name }}
                            </p>
                          </ion-label>
                          <span class="p-r-10" style="position: relative">
                            <fa-icon [style.color]="c2.color === null ? '#e0e0e0' : c2.color" (click)="showColorPicker(c0.categoryId, c1.categoryId, c2.categoryId, '', c2.procSystem)" icon="square" class="f-s-30"></fa-icon>
                          </span>
                          <div class="min-w-20">
                            <div *ngIf="c2.catMenu !== null && c2.catMenu.length > 0">
                              <ion-icon slot="end" name="chevron-down" *ngIf="!c2.open"></ion-icon>
                              <ion-icon slot="end" name="chevron-up" *ngIf="c2.open"></ion-icon>
                            </div>
                          </div>
                        </ion-item>
                        <div *ngIf="c2.open && c2.catMenu !== null && c2.catMenu.length > 0">
                          <div *ngFor="let c3 of c2.catMenu">
                            <ion-item detail="false" color="light" button="true" class="b-s-n i-c-b-n">
                              <ion-label>
                                <p class="menulist p-l-30 c-n" *ngIf="c3.name === ''">-</p>
                                <p class="menulist p-l-30 c-n" *ngIf="c3.name !== ''">
                                  {{ c3.name }}
                                </p>
                              </ion-label>
                              <span class="p-r-10" style="position: relative">
                                <fa-icon [style.color]="c3.color === null ? '#e0e0e0' : c3.color" (click)="showColorPicker(c0.categoryId, c1.categoryId, c2.categoryId, c3.categoryId, c3.procSystem)" icon="square" class="f-s-30"></fa-icon>
                              </span>
                              <div class="min-w-20"></div>
                            </ion-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-title class="f-s-18">{{ "usuarios.edit.userLdap" | translate }}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-grid>
                <ion-row>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>Id:</ion-label>
                  </ion-col>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>{{ profile?.id }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>{{ "usuarios.edit.userLdap" | translate }}:</ion-label>
                  </ion-col>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>{{ profile?.displayName }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>{{ "usuarios.edit.nombre" | translate }}:</ion-label>
                  </ion-col>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>{{ profile?.givenName }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>{{ "usuarios.edit.apellidos" | translate }}:</ion-label>
                  </ion-col>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>{{ profile?.surname }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>Email:</ion-label>
                  </ion-col>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>{{ profile?.mail }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>LDAP:</ion-label>
                  </ion-col>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>{{ profile?.onPremisesSamAccountName }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="isIOS || isAndroid">
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>UUID:</ion-label>
                  </ion-col>
                  <ion-col size-lg="4" size-sm="12" size-xs="12" size="4">
                    <ion-label>{{ uuid }}</ion-label>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
