import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "customRelativeTimePipe",
})
export class CustomRelativeTimePipe implements PipeTransform {
  transform(value: string): string {
    if (value === "este minuto") {
      return "hace unos segundos";
    } else if (value === "this minute") {
      return "a few seconds ago";
    } else if (value === "la semana pasada") {
      return "hace 1 semana";
    } else if (value === "ayer") {
      return "hace 1 día";
    } else if (value === "yesterday") {
      return "1 day ago";
    } else if (value === "anteayer") {
      return "hace 2 días";
    } else if (value === "last week") {
      return "a week ago";
    } else if (value === "el mes pasado") {
      return "hace 1 mes";
    } else if (value === "last month") {
      return "a month ago";
    } else if (value === "el año pasado") {
      return "hace 1 año";
    } else if (value === "last year") {
      return "a year ago";
    } else {
      return value;
    }
  }
}
