<ion-header>
</ion-header>
<ion-content>
  <ion-item color="primary">
    <ion-grid>
      <ion-row>
        <ion-col size="auto">
          <div class="login-form-flex">
            <ion-img class="logo-img" alt="iWorkflow" src="./assets/logos/logo.png"></ion-img>
          </div>
        </ion-col>
        <ion-col size="auto">
          <h5>IWorkflow 3</h5>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
  <ion-item>
    <ion-grid>
      <ion-row>
        <ion-col size="auto">
          <h4><strong>{{'comun.msgLogout'| translate}}</strong></h4>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
</ion-content>