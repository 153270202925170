import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterTasks",
})
export class FilterTasks implements PipeTransform {
  transform(value: any, arg: any): any {
    var min = arg.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    const result = value.filter(
      (t) =>
        (t.name != null && t.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(min) > -1) ||
        (t.description != null && t.description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(min) > -1) ||
        (t.subject != null && t.subject.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(min) > -1)
    );
    return result;
  }
}
