import { HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoadingController } from "@ionic/angular";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Subject, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { MSALPlugin } from "../plugin/MSALPlugin";
import { MsAuthPlugin } from "../plugin/MsAuthPlugin";
import { MonitoringService } from "./monitoring.service";
@Injectable({ providedIn: "root" })
export class GenericService {
  url_gateway = "";
  openTaskFromPush = false;
  catLoadFlag = true;
  taskLoadFlag = true;
  menuLoadFlag = true;
  private customSubject = new Subject<any>();
  constructor(public loadingController: LoadingController, private _snackBar: MatSnackBar, private mon: MonitoringService) {
    this.url_gateway = environment.url_gateway;
  }
  getCatLoadFlag() {
    return this.catLoadFlag;
  }
  setCatLoadFlag(vcatLoadFlag: boolean) {
    this.catLoadFlag = vcatLoadFlag;
    return true;
  }
  getTaskLoadFlag() {
    return this.taskLoadFlag;
  }
  setTaskLoadFlag(vtaskLoadFlag: boolean) {
    this.taskLoadFlag = vtaskLoadFlag;
    return true;
  }
  getMenuLoadFlag() {
    return this.menuLoadFlag;
  }
  setMenuLoadFlag(vmenuLoadFlag: boolean) {
    this.menuLoadFlag = vmenuLoadFlag;
    return true;
  }
  getOpenTaskFromPush() {
    return this.openTaskFromPush;
  }
  setOpenTaskFromPush(openPush: boolean) {
    this.openTaskFromPush = openPush;
    return true;
  }

  async getHeaders(): Promise<HttpHeaders> {
    if (environment.ios) {
      const { authorizeAPI } = MSALPlugin;
      const data = await authorizeAPI({ scope: environment.scopeAPI });
      return new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.token,
      });
    } else if (environment.android) {
      const { authorizeAPI } = MsAuthPlugin;
      let data = await authorizeAPI({
        clientId: environment.clientId,
        tenant: "2d46fb13-81c6-467f-987e-26bcc78a8706",
        domainHint: "DH",
        scopes: [environment.scopeProfileGraph, environment.scopePeopleGraph],
        keyHash: "EjFb6dyoPgPVkRgqHoXXXF2pFTA=",
        scope: environment.scopeAPI,
      });
      return new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.accessToken,
      });
    } else {
      return new HttpHeaders({
        "Content-Type": "application/json",
      });
    }
  }
  async getHeadersGraph(scopeGraph: string): Promise<HttpHeaders> {
    if (environment.ios) {
      const { authorizeAPI } = MSALPlugin;
      const data = await authorizeAPI({ scope: scopeGraph });
      return new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.token,
        ConsistencyLevel: "eventual",
        "X-PeopleQuery-QuerySources": "Mailbox,Directory",
      });
    } else if (environment.android) {
      const { authorizeAPI } = MsAuthPlugin;
      let data = await authorizeAPI({
        clientId: environment.clientId,
        tenant: "2d46fb13-81c6-467f-987e-26bcc78a8706",
        domainHint: "DH",
        scopes: [environment.scopeProfileGraph, environment.scopePeopleGraph],
        keyHash: "EjFb6dyoPgPVkRgqHoXXXF2pFTA=",
        scope: scopeGraph,
      });
      return new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.accessToken,
        ConsistencyLevel: "eventual",
        "X-PeopleQuery-QuerySources": "Mailbox,Directory",
      });
    } else {
      return new HttpHeaders({
        "Content-Type": "application/json",
        ConsistencyLevel: "eventual",
        "X-PeopleQuery-QuerySources": "Mailbox,Directory",
      });
    }
  }

  public handleError() {
    return (err: any) => {
      let errMsg = `error handleError `;
      console.log(JSON.stringify(err));
      if (err instanceof HttpErrorResponse) {
        const error = err.error?.error_description || err.error?.message || err.statusText;
        console.log(error);
        errMsg = error;
      }
      this.publishEvent("close_loading");
      this.mon.appInsights.trackException(err);
      return throwError(() => new Error(errMsg));
    };
  }

  async currectAccount(): Promise<any> {
    const { currectAccount } = MSALPlugin;
    return await currectAccount();
  }
  async loginIOS(): Promise<any> {
    const { loginIOS } = MSALPlugin;
    console.log("loginISO");

    const data = await loginIOS();
    console.log(data);
    return data.account;
  }
  async logoutIOS(): Promise<string> {
    const { logoutIOS } = MSALPlugin;
    const data = await logoutIOS();
    return data.token;
  }

  async loginAnd(): Promise<any> {
    const { loginAnd } = MsAuthPlugin;
    console.log("loginAnd");
    let data = await loginAnd({
      clientId: environment.clientId,
      tenant: "2d46fb13-81c6-467f-987e-26bcc78a8706",
      domainHint: "DH",
      scopes: [environment.scopeProfileGraph, environment.scopePeopleGraph],
      keyHash: "EjFb6dyoPgPVkRgqHoXXXF2pFTA=",
    });
    return data;
  }

  async logoutAnd(): Promise<any> {
    const { logoutAnd } = MsAuthPlugin;
    let data = await logoutAnd({
      clientId: environment.clientId,
      tenant: "2d46fb13-81c6-467f-987e-26bcc78a8706",
      domainHint: "DH",
      keyHash: "EjFb6dyoPgPVkRgqHoXXXF2pFTA=",
    });
    return data;
  }

  public getParams(query, subkey?: string) {
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(query)) {
      const valor = query[key];
      if (valor != null) {
        if (Object.prototype.toString.call(valor) === "[object Date]" && !isNaN(valor)) {
          params = params.append(key.toString(), valor.toDateString());
        } else if (valor instanceof Array) {
          valor.forEach((v, i) => (params = params.append(key.toString(), v)));
        } else if (valor instanceof Object) {
          const paramsObject: HttpParams = this.getParams(valor, key.toString());
          const strParams = paramsObject.keys();
          strParams.forEach((element) => {
            params = params.append(element, paramsObject.get(element));
          });
        } else if (typeof valor === "string") {
          if (valor) {
            params = params.append(key.toString(), valor);
          }
        } else {
          if (subkey) {
            params = params.append(subkey + "." + key.toString(), valor);
          } else {
            params = params.append(key.toString(), valor);
          }
        }
      }
    }
    return params;
  }

  getLocaleBrowser() {
    const userLang = navigator.language;
    return userLang.split("_")[0]; // example en_US -> en
  }

  publishEvent(event: string) {
    this.publishCustomEvent({
      name: event,
    });
  }

  exportJSPDF(name: string, orient: string, header: any[] = [], colsPdf: any[] = [], dataPdf: any[] = []) {
    let altura = 0;
    header.forEach((h) => {
      altura += h.size;
    });
    let doc;
    let alto = 0;
    if (orient == "landscape") {
      doc = new jsPDF("landscape", "pt", "a4");
    } else {
      doc = new jsPDF("portrait", "pt", "a4");
    }
    doc.setFontSize(8);

    doc.addImage("/assets/logos/logo.png", "PNG", 20, 20, 100, 50);
    alto = 0;
    header.forEach((h) => {
      alto += h.size;
      doc.setFontSize(h.size);
      doc.text(h.value, 140, 30 + alto);
    });

    autoTable(doc, {
      tableWidth: "auto",
      styles: {
        fontSize: 8,
        halign: "center",
        overflow: "linebreak",
        cellWidth: "auto",
        valign: "middle",
      },
      columnStyles: {
        Importe: { halign: "right" },
        Total: { halign: "right" },
        Saldo: { halign: "right" },
        "Base sujeta": { halign: "right" },
        "Base no sujeta": { halign: "right" },
        IVA: { halign: "right" },
        "A pagar": { halign: "right" },
      },
      startY: altura + 50,
      headStyles: { fillColor: [56, 128, 255], overflow: "linebreak" },
      columns: colsPdf,
      body: dataPdf,
    });
    const pageCount = doc.internal.getNumberOfPages();
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(String(i) + " / " + String(pageCount), doc.internal.pageSize.width / 2, pageHeight - 10, { align: "center" });
    }
    doc.save(name + new Date().getTime() + ".pdf");
  }

  publishCustomEvent(data: any) {
    this.customSubject.next(data);
  }
  getObservable(): Subject<any> {
    return this.customSubject;
  }

  showMessage(text: string, type?: string, duration = 3) {
    let style = "a-toast";
    switch (type) {
      case "error":
        style = "error-snackbar";
        duration = 10;
        break;
      case "warning":
        style = "warning-snackbar";
        duration = 5;
    }
    this._snackBar.open(text, null, {
      duration: duration * 1000,
      panelClass: style,
    });
  }

  public clearLocalStorage() {
    try {
      localStorage.removeItem("history");
      localStorage.removeItem("userLogin");
      localStorage.removeItem("impersonate");
      localStorage.removeItem("history");
      localStorage.removeItem("catId1");
      localStorage.removeItem("catId2");
      localStorage.removeItem("catId3");
      localStorage.removeItem("name");
      localStorage.removeItem("catLoadFlag");
      localStorage.removeItem("taskLoadFlag");
      localStorage.removeItem("menuLoadFlag");
      localStorage.removeItem("modules");
      localStorage.removeItem("permissions");
      localStorage.clear();
    } catch {
      console.log("No access localstorage");
    }
  }
}
