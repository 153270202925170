import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, lastValueFrom, Observable, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { FilterUser } from "../models/filter/filterUser";
import { GenericResponse } from "../models/response/generic.response";
import { User } from "../models/user";
import { UserGraph } from "../models/userGraph";
import { GenericService } from "./generic.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private customSubject = new Subject<any>();

  constructor(private http: HttpClient, private genericService: GenericService) {}

  public async getMenu(): Promise<Observable<[]>> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/userController/getMenu";
    return this.http.get(URL, { headers: headers }).pipe((data: any) => {
      return data;
    });
  }

  public async findList(filter: FilterUser): Promise<GenericResponse> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/userController/getlist";

    return lastValueFrom(
      this.http
        .get<GenericResponse>(URL, {
          headers: headers,
          params: this.genericService.getParams(filter),
        })
        .pipe(
          map((data: GenericResponse) => {
            return data;
          })
        )
    );
  }

  public async getUserById(nId: number): Promise<GenericResponse> {
    const headers = await this.genericService.getHeaders();
    const params = this.genericService.getParams({ id: nId });
    const URL = this.genericService.url_gateway + "/api/userController/getById";

    return lastValueFrom(
      this.http
        .get<GenericResponse>(URL, {
          headers: headers,
          params: params,
        })
        .pipe(
          map((data: GenericResponse) => {
            return data;
          })
        )
    );
  }

  public async saveUser(item: User): Promise<GenericResponse> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/userController/saverOrUpdate";
    return lastValueFrom(
      this.http
        .post<GenericResponse>(URL, JSON.stringify(item), {
          headers: headers,
        })
        .pipe(
          map((data: GenericResponse) => {
            return data;
          }),
          catchError(this.genericService.handleError())
        )
    );
  }
  public async saveUserLogin(user: User): Promise<User> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/userController/saverOrUpdateLoginUser";
    return firstValueFrom(
      this.http
        .post(URL, user, {
          headers: headers,
        })
        .pipe((data: any) => {
          return data;
        }, catchError(this.genericService.handleError()))
    );
  }

  public async deleteList(ids: any[]) {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/userController/deleteList";
    return this.http
      .post(URL, ids, {
        headers: headers,
      })
      .pipe((data: any) => {
        return data;
      });
  }

  public async impersonateLogin(id: number) {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/userController/impersonateLogin";
    return this.http
      .post(URL, id, {
        headers: headers,
      })
      .pipe((data: any) => {
        return data;
      });
  }
  public async impersonateLogout() {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/userController/impersonateLogout";
    return this.http
      .get(URL, {
        headers: headers,
        responseType: "text",
      })
      .pipe((data: any) => {
        return data;
      });
  }
  public async findUserLogin(user: User): Promise<User> {
    const headers = await this.genericService.getHeaders();
    const URL = this.genericService.url_gateway + "/api/userController/loginUser";
    return firstValueFrom(
      this.http
        .post(URL, user, {
          headers: headers,
        })
        .pipe((data: any) => {
          return data;
        }, catchError(this.genericService.handleError()))
    );
  }

  public async getUserGraph() {
    const headers = await this.genericService.getHeadersGraph(environment.scopeProfileGraph);
    const GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0/me?$select=givenName,surname,id,mail,displayName,onPremisesSamAccountName,userPrincipalName";
    return firstValueFrom(
      this.http
        .get<UserGraph>(GRAPH_ENDPOINT, {
          headers: headers,
        })
        .pipe((data) => {
          return data;
        }, catchError(this.genericService.handleError()))
    );
  }

  public async getContacts(name: string) {
    const headers = await this.genericService.getHeadersGraph(environment.scopePeopleGraph);
    const params = this.genericService.getParams({
      $search: '"' + name + '"',
      $filter: "personType/class eq " + "'Person'",
      $select: "displayName,scoredEmailAddresses",
      $top: 100,
    });

    const GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0/me/people/";
    return firstValueFrom(
      this.http
        .get(GRAPH_ENDPOINT, {
          headers: headers,
          params: params,
        })
        .pipe(
          map((data: any) => {
            return data.value;
          })
        )
    );
  }

  public async setUserLogin(mail: any) {
    let user: User = new User();
    user.mail = mail;
    const findUser = await this.findUserLogin(user);
    if (findUser == null) {
      const userGraph: UserGraph = await this.getUserGraph();
      user.userLdap = userGraph.onPremisesSamAccountName!;
      user.fullName = userGraph.displayName!;
      user.name = userGraph.givenName!;
      user.surname = userGraph.surname!;
      user.mail = userGraph.userPrincipalName!;
      await this.saveUserLogin(user);
    } else if (findUser.deleted) {
      user = findUser;
      user.deleted = false;
      await this.saveUserLogin(user);
    } else {
      user = findUser;
    }
    return user;
  }

  public async registerDevice(appMobility20Id: string, appMobility20Ver: string, deviceId: string, deviceOSVer: string, deviceOS: string, pushId: string): Promise<string> {
    const headers = await this.genericService.getHeaders();
    const params = this.genericService.getParams({
      appID: appMobility20Id,
      appVer: appMobility20Ver,
      deviceId: deviceId,
      deviceOSVer: deviceOSVer,
      deviceOS: deviceOS,
      pushId: pushId,
    });
    const URL = this.genericService.url_gateway + "/api/userController/registerUserDevicePush";
    return firstValueFrom(
      this.http
        .get(URL, {
          headers: headers,
          responseType: "text",
          params: params,
        })
        .pipe((data: any) => {
          return data;
        })
    );
  }

  getObservable(): Subject<any> {
    return this.customSubject;
  }
  publishEvent(event: string) {
    this.publishCustomEvent({
      name: event,
    });
  }

  publishCustomEvent(data: any) {
    this.customSubject.next(data);
  }
}
