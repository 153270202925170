import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { GenericPageComponent } from "src/app/core/generic.page.component";
import { Preference } from "src/app/models/preference";
import { GenericService } from "src/app/provider/generic.service";
import { PreferenceService } from "src/app/provider/preference.service";
import { Device } from "@capacitor/device";
import { UserService } from "src/app/provider/user.service";
import { UserGraph } from "src/app/models/userGraph";
import { environment } from "src/environments/environment";
import { CategoryMenuGrid } from "src/app/models/grid/category-menu-grid";
import { ModalColorPickerComponent } from "src/app/components/preference/modal-color-picker/modal-color-picker.component";
import { MatDialog } from "@angular/material/dialog";
import { UtilsService } from "src/app/provider/utils.service";

@Component({
  selector: "app-preference",
  templateUrl: "./preference.component.html",
})
export class PreferenceComponent extends GenericPageComponent {
  lang: string;
  languages = [
    { value: "es", label: "preference.languageOpt.es" },
    { value: "en", label: "preference.languageOpt.en" },
  ];
  sel1: number;
  sel2: number;
  sel3: number;
  sel4: number;
  completedTasksDwellTimes = [
    { value: 0, label: "preference.completed.0" },
    { value: 30, label: "preference.completed.30" },
    { value: 60, label: "preference.completed.60" },
    { value: 240, label: "preference.completed.240" },
    { value: 480, label: "preference.completed.480" },
    { value: 1440, label: "preference.completed.1440" },
  ];
  failedTasksDwellTimes = [
    { value: 0, label: "preference.timetaskerror.0" },
    { value: 30, label: "preference.timetaskerror.30" },
    { value: 60, label: "preference.timetaskerror.60" },
    { value: 240, label: "preference.timetaskerror.240" },
    { value: 480, label: "preference.timetaskerror.480" },
    { value: 1440, label: "preference.timetaskerror.1440" },
  ];
  automaticsRefresh = [
    { value: 0, label: "preference.timeAutomaticRefresh.0" },
    { value: 2, label: "preference.timeAutomaticRefresh.2" },
    { value: 5, label: "preference.timeAutomaticRefresh.5" },
    { value: 10, label: "preference.timeAutomaticRefresh.10" },
    { value: 30, label: "preference.timeAutomaticRefresh.30" },
  ];
  fontSizes = [
    { value: 1, label: "preference.fontSize.1" },
    { value: 2, label: "preference.fontSize.2" },
    { value: 3, label: "preference.fontSize.3" },
  ];
  ios = true;
  itemDirty: Preference = new Preference();
  item: Preference = new Preference();
  isDirty = true;
  profile: UserGraph;
  uuid: string;
  isIOS: boolean = environment.ios === true;
  isAndroid: boolean = environment.android === true;
  catMenu: CategoryMenuGrid[] = [];
  processOpen = false;
  checked = false;

  constructor(router: Router, genericService: GenericService, translateService: TranslateService, private preferenceService: PreferenceService, private userService: UserService, private dialog: MatDialog, private utilService: UtilsService) {
    super(genericService, router, translateService);
    this.item = new Preference();
    this.itemDirty = new Preference();

    let history: any[] | undefined;
    const localHistory = localStorage.getItem("history");
    if (localHistory) {
      history = JSON.parse(localHistory);
    }

    if (history && history[history.length - 1].extra) {
      this.item.id = history[history.length - 1].extra.id;
    }
    this.loadData();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.isDirty && JSON.stringify(this.item) !== JSON.stringify(this.itemDirty)) {
      return false;
    }
    return true;
  }

  public onChangeNum(field: number, key: number) {
    if (field == 1) {
      this.item.completedTasksDwellTime = key;
    }
    if (field == 2) {
      this.item.failedTasksDwellTime = key;
    }
    if (field == 3) {
      this.item.automaticRefresh = key;
    }
    if (field == 4) {
      this.item.fontSize = key;
    }
  }
  public onChange(key: string) {
    this.item.language = key;
  }
  async findPreference(Ref: boolean) {
    await this.preferenceService
      .findPreferenceByUser(Ref)
      .then((data) => {
        this.item = new Preference(data);
        this.lang = this.item.language;
        this.sel1 = this.item.completedTasksDwellTime;
        this.sel2 = this.item.failedTasksDwellTime;
        this.sel3 = this.item.automaticRefresh;
        this.sel4 = this.item.fontSize;
        this.itemDirty = new Preference(data);
      })
      .catch((error) => {
        console.log("IWF3 findPreference KO", error);
      });
  }

  async AllProcessList() {
    await this.preferenceService
      .getProcessList()
      .then((data) => {
        this.catMenu = data;
      })
      .catch((error) => {
        console.log("IWF3 AllProcessList KO", error);
      });
  }

  async loadData(): Promise<void> {
    this.genericService.publishEvent("activete_loading");
    await this.findPreference(false);
    this.getProfile();
    await this.AllProcessList();
    if (this.isIOS || this.isAndroid) {
      this.uuid = (await Device.getId()).identifier;
    }
    this.genericService.publishEvent("close_loading");
  }

  public async save() {
    this.genericService.publishEvent("activete_loading");
    this.isDirty = false;
    this.item.catMenu = this.catMenu;

    await this.preferenceService
      .save(this.item)
      .then(async () => {
        this.useLanguage(this.item.language);
        this.utilService.changeFontSize(this.item.fontSize);
        await this.findPreference(true);
        this.genericService.publishEvent("close_loading");
      })
      .catch((error) => {
        console.log("IWF3 this.preferenceService.save KO", error);
        this.genericService.showMessage(this.translateService.instant("validation.saveError"));
        this.genericService.publishEvent("close_loading");
      });
  }

  public useLanguage(language: string) {
    this.translateService.use(language).subscribe((e) => {
      this.genericService.showMessage(this.translateService.instant("validation.saveCorrect"));
    });
  }
  async getProfile() {
    this.profile = await this.userService.getUserGraph();
  }
  showColorPicker(catId1: string, catId2: string, catId3: string, catId4: string, procSystem: string) {
    const dialogRef = this.dialog.open(ModalColorPickerComponent, {
      width: "258px",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res != undefined) {
        let cat = this.searchParent(catId1, this.catMenu, procSystem);
        if (cat == undefined) {
          return null;
        }
        cat = this.findCat(cat, catId2, procSystem, catId3, catId4);
        if (cat != undefined && cat.color != res.value) {
          cat.color = res.value;
        }

        if (cat != undefined && res.checked == true) {
          this.clearColor(cat.catMenu);
        }
      }
    });
  }

  private findCat(cat: CategoryMenuGrid | undefined, catId2: string, procSystem: string, catId3: string, catId4: string) {
    if (cat !== undefined && catId2 != "") {
      cat = this.searchParent(catId2, cat.catMenu, procSystem);
      if (cat !== undefined && catId3 != "") {
        cat = this.searchParent(catId3, cat.catMenu, procSystem);
        if (cat !== undefined && catId4 != "") {
          cat = this.searchParent(catId4, cat.catMenu, procSystem);
        }
      }
    }
    return cat;
  }

  searchParent(id: string, list: CategoryMenuGrid[], procSystem: string): CategoryMenuGrid | undefined {
    return list.find((obj) => obj.categoryId === id && obj.procSystem === procSystem);
  }

  clearColor(childList: CategoryMenuGrid[]) {
    if (childList != null) {
      for (let i = 0; i < childList.length; i++) {
        const child = childList[i];
        child.color = null;
        this.clearColor(child.catMenu);
      }
    }
  }
}
