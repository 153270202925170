import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationStart, ResolveEnd, Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { Subscription } from "rxjs";
import { CategoryService } from "./provider/categoryservice";
import { GenericService } from "./provider/generic.service";
import { UserService } from "./provider/user.service";
import { UtilsService } from "./provider/utils.service";
import packageJson from "../../package.json";
import { AccountInfo } from "@azure/msal-browser";
import { environment } from "../environments/environment";
import { MonitoringService } from "./provider/monitoring.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponentWeb implements OnDestroy, OnInit {
  loginDisplay = false;
  loading = true;
  public version: string = packageJson.version;
  private loadingEventSubscription: Subscription;
  private routerEventSubscription: Subscription;
  private userLoginLogoutEventSubscription: Subscription;
  refreshTime: Date;
  synchronizeSubscription: Subscription;
  refreshFlag = true;
  message: string;

  constructor(private authService: MsalService, private router: Router, private genericService: GenericService, private userService: UserService, private utilsService: UtilsService, private categoryService: CategoryService, private mon: MonitoringService) {
    this.loadingEventSubscription = this.genericService.getObservable().subscribe((result) => {
      if (result) {
        if (result.name === "activete_loading") {
          if (!this.loading) {
            this.loading = true;
          }
        } else if (result.name === "close_loading") {
          if (this.loading) {
            this.loading = false;
          }
        }
      }
    });
    this.synchronizeSubscription = this.categoryService.getObservable().subscribe((result) => {
      if (result && result.name === "refresh_time") {
        this.refreshFlag = true;
        this.refreshTime = new Date();
        localStorage.setItem("refreshTime", JSON.stringify(this.refreshTime));
      } else if (result && result.name === "refresh_error") {
        this.refreshFlag = false;
      }
      if (result && (result.name == "user_cat" || result.name == "user_cat_back")) {
        this.refreshTime = null;
      }
    });
    this.userLoginLogoutEventSubscription = this.userService.getObservable().subscribe((result) => {
      if (result && result.name === "user_login") {
        this.mon.logEvent("IWF3 LOGIN user_login");
        this.setLoginDisplay(true);
      } else if (result && result.name === "user_logout") {
        this.mon.logEvent("IWF3 LOGIN user_logout");
        this.setLoginDisplay(false);
      }
    });
    this.routerEventSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof ResolveEnd) {
        const activatedComponent = this.mon.getActivatedComponent(e.state.root);
        if (activatedComponent) {
          this.mon.logPageView(`${activatedComponent.name} ${this.mon.getRouteTemplate(e.state.root)}`, e.urlAfterRedirects);
        }
      }
      if (e instanceof NavigationStart) {
        this.mon.logPageView("APPINI APProuter", e.url);
        let history: any[];
        if (localStorage.getItem("history")) history = JSON.parse(localStorage.getItem("history"));
        if (history != null && history.length >= 1) {
          if (e.url != "/" && e.url != history[history.length - 1].url) {
            history.push({
              url: e.url,
              extra: this.router.getCurrentNavigation().extras.state,
            });
            localStorage.setItem("history", JSON.stringify(history));
          }
        } else {
          localStorage.setItem("history", JSON.stringify([{ url: e.url, extra: e.restoredState }]));
        }
      }
    });
  }

  ngOnInit(): void {
    const url = this.router.url;
    this.refreshWhenBackButtonBrowser();
    if (url.includes("logout")) {
      this.loginDisplay = false;
    } else {
      const account = this.getActiveAccount();
      if (account) {
        this.loginDisplay = true;
        this.setLoginDisplay(true);
      }
    }
  }

  private getActiveAccount(): AccountInfo {
    try {
      let activeAccount = this.authService.instance.getActiveAccount();
      if (!activeAccount && this.authService.instance.getAllAccounts().length) {
        activeAccount = this.authService.instance.getAllAccounts()[0];
        this.authService.instance.setActiveAccount(activeAccount);
      }
      return activeAccount;
    } catch (error) {
      this.mon.logEvent("IWF3 getActiveAccount error");
      this.mon.logException(error);
      return null;
    }
  }

  ngOnDestroy(): void {
    this.loadingEventSubscription.unsubscribe();
    this.routerEventSubscription.unsubscribe();
    this.userLoginLogoutEventSubscription.unsubscribe();
  }

  async logout() {
    this.loading = true;
    const imper = localStorage.getItem("impersonate");
    if (imper == null) {
      this.loading = false;
      this.authService.logoutRedirect();
    } else {
      (await this.userService.impersonateLogout()).subscribe({
        next: async () => {
          this.loading = false;
          localStorage.removeItem("impersonate");
          await this.utilsService.findPref();
          this.utilsService.publishUserMenuUserCat();
        },
        error: (error: any) => {
          this.loading = false;
          this.genericService.showMessage(JSON.stringify(error));
        },
      });
    }
  }

  private async setLoginDisplay(login: boolean) {
    const account = this.authService.instance.getActiveAccount();
    if (login && account && account.idTokenClaims != undefined) {
      try {
        this.mon.setUserId(account.username);
        this.mon.logEvent("IWF3 setLoginDisplay", account);
        const userLogin = await this.userService.setUserLogin(account.idTokenClaims["upn"]);
        if (userLogin) {
          await this.utilsService.findPref();
          this.utilsService.publishUserMenuUserCat();
          this.loginDisplay = true;

          const navigationType = (window.performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming).type;

          if (navigationType === "navigate") {
            this.utilsService.writeStatsUser(this.version);
          }
        } else {
          this.mon.logEvent("IWF3 setLoginDisplay else userLogin", account);
          this.clearLogin();
        }
      } catch {
        this.mon.logEvent("IWF3 setLoginDisplay CATCH ", account);
        this.clearLogin();
      }
    } else {
      this.mon.logEvent("IWF3 setLoginDisplay else login ", {
        login: login,
        account: account,
      });
      this.clearLogin();
    }
  }

  private clearLogin() {
    this.genericService.clearLocalStorage();
    this.loading = false;
    this.loginDisplay = false;
  }

  synchronize() {
    this.mon.logEvent("IWF3 synchronize");
    this.refreshFlag = true;
    this.refreshTime = null;
    this.categoryService.publishEvent("user_cat");
  }

  refreshWhenBackButtonBrowser() {
    this.mon.logEvent("IWF3 refreshWhenBackButtonBrowser");
    window.addEventListener("popstate", function (event) {
      if (location.href == environment.redirectUri + "/") {
        history.go();
      }
    });
  }
}
